import { ExclamationCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import { Page } from 'main-page/pages';
import React, { useState } from 'react';
import { CreditCheckRequestDto, useApi } from '../../../lib/api';
import { useFormState } from '../../../lib/state';
import { NavigationContainer } from '../../components';
import '../../styles/common.scss';
import { ViewProps } from '../view-props';
import './credibility-check-view.scss';

export const CredibilityCheckView: React.FC<ViewProps> = ({ nextPage }: ViewProps) => {
    const api = useApi();
    const { state, updateState } = useFormState();
    const [isLoading, setLoading] = useState<boolean>(false);

    const onClick = async (): Promise<void> => {
        if (state.procedureId == null || state.personalData?.validated != 'yes') {
            nextPage(Page.CredibilityCheckFailedView, false);
            return;
        }

        let result = false;
        try {
            setLoading(true);
            const data: CreditCheckRequestDto = {
                ...state.personalData,
                procedureId: state.procedureId,
                surname: state.personalData.lastName,
                dateOfBirth: state.personalData.dateOfBirth?.format('YYYY-MM-DD'),
            };
            const response = await api.postCreditCheck(data);
            if (response?.status == 'accepted') {
                updateState({
                    ...state,
                    paymentDetails: {
                        ...state.paymentDetails,
                        uuid: response.uuid,
                        credibilityChecked: true,
                        validated: 'no',
                    },
                });
                result = true;
            }
        } finally {
            setLoading(false);
        }

        if (result) {
            nextPage(Page.VehicleDataView, false);
        } else {
            nextPage(Page.CredibilityCheckFailedView, false);
        }
    };

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Bonitätsprüfung'}</Typography>
            </div>
            <div className='view-card-bottom'>
                <div className='boxed'>
                    <Typography className='padded-control header centered'>
                        {'Für den Abschluss führen wir einen kurzen Bonitäts-Check durch.'}
                    </Typography>
                </div>
                <div className='text-paragraph'>
                    <Typography className='padded-control centered' style={{ fontSize: '1rem' }}>
                        {
                            'Der Check dauert nur wenige Sekunden. Sie können im Anschluss Ihre Autoversicherung abschließen.'
                        }
                    </Typography>
                </div>
                <div className='padded-control infobox'>
                    <ExclamationCircleFilled style={{ color: '#0083a9' }} />
                    <Typography className='infobox'>
                        {
                            'Für die Überprüfung Ihrer Bonität übermitteln wir die hierfür erforderlichen Daten an CRIF GmbH, Rothschildplatz 3 / Top 3.06.B, 1020 Wien, die als selbstständiger Verantwortlicher die übermittelten Daten für ihre eigenen Zwecke zur Auskunftei und Adressverlag, wie unter '
                        }
                        <Link
                            target='_blank'
                            rel='noreferrer'
                            href={'https://www.crif.at/datenschutz/'}
                        >
                            <u>{'https://www.crif.at/datenschutz/'}</u>
                        </Link>
                        {' beschrieben, verarbeitet.'}
                    </Typography>
                </div>
                <NavigationContainer
                    forwardText={'Bonitätscheck durchführen'}
                    forward={onClick}
                    isLoading={isLoading}
                />
            </div>
            <div className='view-card-bottom' style={{ background: 'transparent' }} />
        </div>
    );
};
