import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { FailedSubmitState } from './failed-submit-state';
import { PaymentDetailsState } from './payment-details-state';
import { PersonalDataState } from './personal-data-state';
import { VehicleDataState } from './vehicle-data-state';
import { NeedAnalysisState } from './need-analysis-state';
import dayjs from 'dayjs';

export type FormState = {
    procedureId?: string | undefined;
    paymentDetails: PaymentDetailsState;
    needAnalysis: NeedAnalysisState;
    personalData: PersonalDataState;
    vehicleData: VehicleDataState[];
    submitData?: FailedSubmitState[] | undefined;
};

export type UseFormState = {
    state: FormState;
    updateState: (value: FormState) => void;
    resetState: () => void;
};

export const FormStateContext: React.Context<UseFormState> =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createContext<UseFormState>(undefined!);

type Props = {
    children?: ReactNode;
};

export const FormStateProvider: React.FC<Props> = ({ children }: Props) => {
    const updateState = (value: FormState): void => {
        setState({
            paymentDetails: { ...value.paymentDetails },
            personalData: { ...value.personalData },
            needAnalysis: { ...value.needAnalysis },
            vehicleData: value.vehicleData,
            procedureId: value.procedureId,
            submitData: value.submitData,
        });
    };

    const [state, setState] = useState<FormState>({
        paymentDetails: { validated: 'no' },
        personalData: { validated: 'no' },
        needAnalysis: { date: dayjs() },
        vehicleData: [],
        procedureId: undefined,
        submitData: undefined,
    });

    const resetState = (): void => {
        setState({
            paymentDetails: { validated: 'no' },
            personalData: { validated: 'no' },
            needAnalysis: { date: dayjs() },
            vehicleData: [],
            procedureId: undefined,
            submitData: undefined,
        });
    };

    const context = useMemo(() => {
        return {
            state,
            updateState,
            resetState,
        };
    }, [state]);

    return <FormStateContext.Provider value={context}>{children}</FormStateContext.Provider>;
};

export const useFormState = (): UseFormState => {
    const context = useContext(FormStateContext);
    if (!context) {
        throw new Error('FormStateContext was not set in application tree.');
    }

    return context;
};
