export enum Documents {
    AVB_Base = 'https://www.wienerstaedtische.at/online-abschluesse/bedingung/pdf/AVB_Kfz_UBER',
    AGB = '/static/documents/AGB.pdf',
    Maklervertrag = '/static/documents/Maklervertrag.pdf',
    // Haftpflicht = '/static/documents/Produktinformationen Haftpflicht.pdf',
    Haftpflicht = 'https://www.wienerstaedtische.at/fileadmin/user_upload/Dokumente/IPID/IPID_KFZ_Haftpflichtversicherung2021_SorgenfreiMobil.pdf',
    Lenkerschutz = '/static/documents/Produktinformationen Lenkerunfall Schutz.pdf',
    // Kasko = '/static/documents/Produktinformationen Kasko.pdf',
    Kasko = 'https://www.wienerstaedtische.at/fileadmin/user_upload/Dokumente/IPID/IPID_KFZ_Vollkaskoversicherung_SorgenfreiMobil.pdf',
    ElektronischeKommunikation = '/static/documents/Vereinbarung_der_elektronischen_Kommunikation.pdf',
    Datenschutz = '/static/documents/Datenschutzhinweise der Aon Austria GmbH.pdf',
}
