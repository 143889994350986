import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { AppHeader } from './app-header';
import './main-page.scss';
import { Page } from './pages';
import {
    CarCountChoiceView,
    CredibilityCheckView,
    CredibilityFailedView,
    ErrorView,
    FinCheckFailedView,
    LandingView,
    MajorCustomerDataView,
    MajorCustomerResultView,
    PaymentView,
    PersonalDetailsView,
    ResultView,
    SubmitFailureView,
    SubmitLoadingProps,
    SubmitLoadingView,
    SummaryView,
    VehicleDataView,
    VehicleSummaryView,
} from './views';
import { NeedsAnalysisView } from './views/needs-analysis-view/needs-analysis-view';

export const MainPage: React.FC = () => {
    const anchor = useRef<HTMLDivElement | null>(null);

    const [pageHistory, setHistory] = useState<Page[]>([]);
    const [currentPage, setCurrentPage] = useState<Page | undefined>(undefined);
    const [submitParameters, setSubmitParameters] = useState<SubmitLoadingProps | undefined>(
        undefined
    );

    const nextPage = (page: Page, addToHistory = true): void => {
        if (addToHistory && currentPage != null) {
            const newHistory = [...pageHistory, currentPage];
            setHistory(newHistory);
        }

        setCurrentPage(page);
    };

    const previousPage = (): void => {
        const page = pageHistory.at(-1);
        setCurrentPage(page);
        const newHistory = pageHistory.slice(0, pageHistory.length - 1);
        setHistory(newHistory);
    };

    useEffect(() => {
        if (currentPage == null) {
            setCurrentPage(Page.LandingView);
        }
    }, []);

    useEffect(() => {
        if (anchor.current) {
            document.documentElement.scrollTop = anchor.current.offsetTop;
        }
    }, [currentPage]);

    const pageToDisplay = useMemo<ReactNode>(() => {
        switch (currentPage) {
            case Page.ErrorViewDefault:
                return (
                    <ErrorView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.ErrorViewMissingLinkId:
                return (
                    <ErrorView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        message='Der Link ist ungültig. Überprüfen Sie, ob Sie den korrekten Link aufgerufen haben.'
                        showButton={false}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.LandingView:
                return (
                    <LandingView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.NeedsAnalysisView:
                return (
                    <NeedsAnalysisView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.CarCountChoiceView:
                return (
                    <CarCountChoiceView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.MajorCustomerDataView:
                return (
                    <MajorCustomerDataView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.MajorCustomerResultView:
                return (
                    <MajorCustomerResultView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.PersonalDetailsView:
                return (
                    <PersonalDetailsView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.CredibilityCheckFailedView:
                return (
                    <CredibilityFailedView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.CredibilityCheckView:
                return (
                    <CredibilityCheckView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.FinCheckFailedView:
                return (
                    <FinCheckFailedView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.VehicleDataView:
                return (
                    <VehicleDataView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentIndex={0}
                        setSubmitParameters={setSubmitParameters}
                        navigateToNeedsAnalysis={() => setCurrentPage(Page.NeedsAnalysisView)}
                    />
                );

            case Page.VehicleSummaryView:
                return (
                    <VehicleSummaryView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.SummaryView:
                return (
                    <SummaryView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.PaymentView:
                return (
                    <PaymentView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.SubmitLoadingView:
                return (
                    <SubmitLoadingView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        longRunningAction={submitParameters?.longRunningAction ?? (() => false)}
                        successRedirect={submitParameters?.successRedirect ?? Page.ErrorViewDefault}
                        failureRedirect={submitParameters?.failureRedirect ?? Page.ErrorViewDefault}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.SubmitFailureView:
                return (
                    <SubmitFailureView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        submit={submitParameters?.longRunningAction ?? (() => false)}
                        setSubmitParameters={setSubmitParameters}
                    />
                );

            case Page.SubmitSuccessView:
                return <ResultView />;
        }
    }, [currentPage, submitParameters]);

    return (
        <div>
            <AppHeader
                currentPage={currentPage as Page}
                nextPage={nextPage}
                previousPage={previousPage}
                setSubmitParameters={setSubmitParameters}
            />
            <div ref={anchor} />
            {pageToDisplay}
        </div>
    );
};
