import { Typography } from 'antd';
import React from 'react';
import { NavigationContainer } from '../../components';
import '../../styles/common.scss';
import { ViewProps } from '../view-props';
import './fin-check-failed-view.scss';

export const FinCheckFailedView: React.FC<ViewProps> = ({ previousPage }: ViewProps) => {
    const onClick = (): void => {
        if (previousPage) {
            previousPage();
        }
    };

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'Welche Fahrzeuge möchten Sie versichern?'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <div className='boxed'>
                    <Typography className='padded-control header centered'>
                        {'Ups, da ist etwas schief gelaufen.'}
                    </Typography>
                </div>
                <div className='boxed text-paragraph'>
                    <Typography className='padded-control centered' style={{ fontSize: '1rem' }}>
                        {'Wir konnten Ihre eingegebene FIN leider nicht finden.'}
                    </Typography>
                </div>
                <div className='text-paragraph'>
                    <Typography className='padded-control centered' style={{ fontSize: '1rem' }}>
                        {'Versuchen Sie es bitte erneut.'}
                    </Typography>
                </div>
                <NavigationContainer forwardText='Zurück zur FIN-Eingabe' forward={onClick} />
            </div>
        </div>
    );
};
