import { Col, Divider, Row, Typography } from 'antd';
import { formatAsCurrency } from 'lib/shared';
import { Page } from 'main-page/pages';
import React from 'react';
import { useFormState } from '../../../lib/state';
import { DocumentLink, Documents, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';
import './vehicle-summary-view.scss';
import { ExclamationCircleFilled } from '@ant-design/icons';

export const VehicleSummaryView: React.FC<ViewProps> = ({ nextPage, previousPage }: ViewProps) => {
    const { state } = useFormState();

    const forward = (): void => {
        nextPage(Page.PaymentView);
    };

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'Ihr Angebot für Ihre Fahrzeuge'}
                </Typography>
            </div>
            <div className='view-card'>
                <Typography className='padded-control header'>{'Leistungsüberblick'}</Typography>
                <Divider
                    style={{
                        borderColor: 'rgba(0, 0, 0, 0.75)',
                        margin: '0 1rem',
                        width: ' auto',
                        minWidth: 'auto',
                    }}
                />
                <div className='padded-control'>
                    <Typography className='padded-control header' style={{ marginBottom: '1rem' }}>
                        {'Kfz-Haftpflichtversicherung'}
                    </Typography>
                    <div className='padded-control' style={{ marginBottom: '1.5rem' }}>
                        <Typography className='padded-control'>
                            {
                                'Versicherungssumme 30 Mio. EUR inkl. Pannenhilfe und gemietete Kraftfahrzeuge im Ausland'
                            }
                        </Typography>
                    </div>
                    <Typography className='padded-control'>
                        {'Schadenersatzbeitrag 400 €'}
                    </Typography>
                    <DocumentLink
                        title='Produktinformationsblatt Kfz-Haftpflichtversicherung'
                        filePath={Documents.Haftpflicht}
                        className='padded-control'
                    />
                    <Divider
                        style={{
                            borderColor: 'rgba(0, 0, 0, 0.3)',
                            margin: '2rem 1rem',
                            width: ' auto',
                            minWidth: 'auto',
                        }}
                    />
                    <Typography className='padded-control header' style={{ marginBottom: '1rem' }}>
                        {'+ Vollkaskoversicherung'}
                    </Typography>
                    <Typography className='padded-control'>
                        {'mit generellem Selbstbehalt 1.000 €'}
                    </Typography>
                    <DocumentLink
                        title='Produktinformationsblatt Kfz-Vollkaskoversicherung'
                        filePath={Documents.Kasko}
                        className='padded-control'
                    />
                    {state.paymentDetails.passengerAccident && (
                        <>
                            <Divider
                                style={{
                                    borderColor: 'rgba(0, 0, 0, 0.3)',
                                    margin: '2rem 1rem',
                                    width: ' auto',
                                    minWidth: 'auto',
                                }}
                            />
                            <Typography
                                className='padded-control header'
                                style={{ marginBottom: '1rem' }}
                            >
                                {'+ Kfz-Lenkerunfall-Schutz'}
                            </Typography>
                            <DocumentLink
                                title='Produktinformationsblatt Kfz-Unfallversicherung'
                                filePath={Documents.Lenkerschutz}
                                className='padded-control'
                            />
                        </>
                    )}
                </div>
                <div
                    className='padded-control'
                    style={{
                        background: 'rgba(0, 131, 169, 0.1)',
                        border: '1px solid #0083a9',
                        borderRadius: '6px',
                        marginTop: '2.5rem',
                        padding: '0.75rem 1.25rem',
                    }}
                >
                    <Typography className='header' style={{ marginBottom: '0.5rem' }}>
                        {'Highlights'}
                    </Typography>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '24px 1fr',
                            rowGap: '1rem',
                        }}
                    >
                        <Typography
                            className='checked'
                            style={{ marginLeft: '0.25rem', marginTop: '0.1rem' }}
                        />
                        <Typography>
                            {
                                'Bruch von Kleingläsern wie Scheinwerfergläser, Blinkercellonen oder Außenspiegeln inklusive'
                            }
                        </Typography>
                        <Typography
                            className='checked'
                            style={{ marginLeft: '0.25rem', marginTop: '0.1rem' }}
                        />
                        <Typography>
                            {
                                'Bei Einbruch in Ihr Fahrzeug ist der Diebstahl von Gegenständen des persönlichen Bedarfs (ohne Elektronik-, Sport- und Hobbygeräte) versichert.'
                            }
                        </Typography>
                    </div>
                </div>
                <Typography className='padded-control' style={{ marginTop: '1rem' }}>
                    {
                        'Weitere Einzelheiten zu den Deckungsinhalten finden Sie in den Produkt-Informationsblättern, die eine detaillierte Übersicht über den Versicherungsschutz bieten.'
                    }
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography className='padded-control header'>{'Prämienauskunft'}</Typography>
                <Divider
                    style={{
                        borderColor: 'rgba(0, 0, 0, 0.75)',
                        margin: '0 1rem',
                        width: ' auto',
                        minWidth: 'auto',
                    }}
                />
                {state.vehicleData.map((car, index) => {
                    return (
                        <div
                            className='padded-control'
                            style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                borderRadius: '4px',
                                paddingBottom: '1rem',
                                paddingTop: '1rem',
                            }}
                            key={`vehicle-summary-${index}`}
                        >
                            <div className='padded-control'>
                                <Typography className='padded-control'>
                                    <b>{`Taxifahrzeug ${index + 1}`}</b>
                                </Typography>
                                <Typography className='padded-control'>
                                    {car.changeInsurer ? 'Versicherungswechsel' : 'Neufahrzeug'}
                                </Typography>
                                {car.changeInsurer && (
                                    <div
                                        style={{
                                            paddingBottom: '1rem',
                                            paddingTop: '1rem',
                                        }}
                                    >
                                        <Typography className='padded-control'>
                                            <Typography>
                                                {'Kennzeichen '}
                                                <b>{car.licensePlateNumber}</b>
                                            </Typography>
                                        </Typography>
                                    </div>
                                )}
                                <Typography className='padded-control'>
                                    <b>{car.manufacture}</b>
                                </Typography>
                                <Typography className='padded-control'>
                                    <b>{car.carName}</b>
                                </Typography>
                                <div
                                    className='padded-control'
                                    style={{ paddingTop: '1rem', width: '260px' }}
                                >
                                    <Row>
                                        <Col span={12}>
                                            <Typography>{'Leistung '}</Typography>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <b>{`${car.power} kW`}</b>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Typography>{'Bruttolistenpreis '}</Typography>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <b>{formatAsCurrency(car.grossListPrice)}</b>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Typography>{'Sonderausstattung '}</Typography>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <b>{formatAsCurrency(car.optionalEquipment)}</b>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div
                                className='padded-control'
                                style={{
                                    border: '1px solid #0083a9',
                                    borderRadius: '6px',
                                    padding: '0.25rem',
                                }}
                            >
                                <div
                                    className='padded-control'
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        rowGap: '0.4rem',
                                    }}
                                    key={`price-gross-${index}`}
                                >
                                    <Typography className='blue header'>
                                        {'Jahresprämie'}
                                    </Typography>
                                    <div />
                                    <Typography className='blue'>
                                        {'Haftpflichtversicherung'}
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography className='blue'>
                                            {formatAsCurrency(
                                                car.rate?.components
                                                    ?.filter((rate) => rate.type === 'Haftpflicht')
                                                    .reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.annualGrossPremium != null
                                                                ? rate.annualGrossPremium
                                                                : 0),
                                                        0
                                                    )
                                            )}
                                        </Typography>
                                    </div>
                                    <Typography className='blue'>{'Kaskoversicherung'}</Typography>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography className='blue'>
                                            {formatAsCurrency(
                                                car.rate?.components
                                                    ?.filter((rate) => rate.type === 'Kasko')
                                                    .reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.annualGrossPremium != null
                                                                ? rate.annualGrossPremium
                                                                : 0),
                                                        0
                                                    )
                                            )}
                                        </Typography>
                                    </div>
                                    {state.paymentDetails.passengerAccident && (
                                        <>
                                            <Typography className='blue'>
                                                {'Kfz-Lenkerunfall-Schutz'}
                                            </Typography>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flexGrow: 1 }} />
                                                <Typography className='blue'>
                                                    {formatAsCurrency(
                                                        car.rate?.components?.find(
                                                            (rate) => rate.type === 'InsassenUnfall'
                                                        )?.annualGrossPremium
                                                    )}
                                                </Typography>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <Divider
                                    style={{
                                        borderColor: '#0083a9',
                                        margin: '0 0.5rem',
                                        width: ' auto',
                                        minWidth: 'auto',
                                    }}
                                />
                                <div
                                    className='padded-control'
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                        rowGap: '0.4rem',
                                    }}
                                    key={`price-total-${index}`}
                                >
                                    <Typography className='blue'>
                                        <b>{'Gesamt'}</b>
                                    </Typography>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography className='blue'>
                                            <b>
                                                {formatAsCurrency(
                                                    car.rate?.components?.reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.annualGrossPremium != null
                                                                ? rate.annualGrossPremium
                                                                : 0),
                                                        0
                                                    )
                                                )}
                                            </b>
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', padding: '0 0.5rem' }}>
                                    <div style={{ flexGrow: 1 }} />
                                    <Typography style={{ fontSize: '0.8rem' }}>
                                        {'inkl. Versicherungssteuer'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div
                    className='padded-control'
                    style={{
                        backgroundColor: '#0083a9',
                        borderRadius: '4px',
                        marginTop: '2rem',
                    }}
                >
                    <div
                        className='padded-control'
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '2fr 1fr',
                            padding: '0.75rem',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className='in-box'>
                                <b>{'Jahresprämie gesamt / alle Fahrzeuge'}</b>
                            </Typography>
                            <Typography className='in-box'>
                                {'inkl. Versicherungssteuer'}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }} />
                            <Typography className='in-box'>
                                <b>
                                    {formatAsCurrency(
                                        state.vehicleData.reduce((total, vehicle) => {
                                            const components = vehicle.rate?.components;
                                            if (components == null) {
                                                return total;
                                            }

                                            return (
                                                total +
                                                components.reduce(
                                                    (result, rate) =>
                                                        result +
                                                        (rate?.annualGrossPremium != null
                                                            ? rate.annualGrossPremium
                                                            : 0),
                                                    0
                                                )
                                            );
                                        }, 0)
                                    )}
                                </b>
                            </Typography>
                        </div>
                    </div>
                </div>
                {state.paymentDetails.frequency === 'monatlich' && (
                    <div className='padded-control'>
                        <div
                            className='padded-control'
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '2fr 1fr',
                                padding: '0.75rem',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <b>{'monatlich'}</b>
                                </Typography>
                                <Typography>{'inkl. Versicherungssteuer'}</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 1 }} />
                                <Typography>
                                    <b>
                                        {formatAsCurrency(
                                            state.vehicleData.reduce((total, vehicle) => {
                                                const components = vehicle.rate?.components;
                                                if (components == null) {
                                                    return total;
                                                }

                                                return (
                                                    total +
                                                    components.reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.gross != null ? rate.gross : 0),
                                                        0
                                                    )
                                                );
                                            }, 0)
                                        )}
                                    </b>
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
                {state.vehicleData.some((car) => car.leasing) && (
                    <div className='padded-control infobox'>
                        <ExclamationCircleFilled style={{ color: '#0083a9' }} />
                        <Typography className='infobox'>
                            {
                                'Für die Vinkulierung wird eine einmalige Gebühr in Höhe von EUR 15,- (inkl. 20% Ust.) pro Fahrzeug eingehoben.'
                            }
                        </Typography>
                    </div>
                )}
                <NavigationContainer
                    forwardText='Übernehmen'
                    forward={forward}
                    backwardText='Zurück'
                    backward={previousPage}
                />
            </div>
        </div>
    );
};
