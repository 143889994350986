import { Typography } from 'antd';
import React from 'react';

export const ResultView: React.FC = () => {
    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Ihre Versicherung'}</Typography>
            </div>
            <div className='view-card-bottom'>
                <div
                    style={{
                        backgroundColor: '#f9f9f9',
                        borderRadius: '50%',
                        display: 'grid',
                        marginTop: '2rem',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '5rem',
                        height: '5rem',
                        gridTemplateColumns: '1fr auto 1fr',
                        gridTemplateRows: '1fr auto 1fr',
                        gridTemplateAreas: '". . ." ". check ." ". . ."',
                    }}
                >
                    <div
                        style={{
                            borderColor: 'green',
                            borderWidth: '0 0 4px 4px',
                            borderStyle: 'solid',
                            gridArea: 'check',
                            width: '2rem',
                            height: '1rem',
                            transform: 'rotateY(0deg) rotate(-45deg)',
                        }}
                    ></div>
                </div>
                <Typography
                    className='header'
                    style={{
                        marginTop: '2rem',
                        paddingLeft: '4rem',
                        paddingRight: '4rem',
                        textAlign: 'center',
                    }}
                >
                    {'Vielen Dank für Ihren Antrag.'}
                </Typography>
                <Typography className='padded-control'>
                    {
                        'Ihr Antrag ist bei uns eingegangen und befindet sich gerade in Prüfung. Sie erhalten in Kürze eine E-Mail zu Ihrem Status und Ihrer Versicherung.'
                    }
                </Typography>
                <Typography className='padded-control'>{'Beste Grüße'}</Typography>
                <Typography className='padded-control'>
                    <b>{'Ihr Aon Team!'}</b>
                </Typography>
            </div>
        </div>
    );
};
