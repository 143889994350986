import { useFormState } from '../../../lib/state';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { FinCheckView } from '../fin-check-view';
import { Page } from '../../pages';
import { Button, Typography } from 'antd';
import { MinusCircleFilled } from '@ant-design/icons';
import { VehicleDataVehicleForm } from './vehicle-data-vehicle-form';

interface Props {
    currentIndex: number;
    poweredByOptions: string[];
    leasingCompanyOptions: string[];
}

export const VehicleController = forwardRef<any, Props>((props, ref) => {
    const { currentIndex, poweredByOptions, leasingCompanyOptions } = props;
    const { state, updateState } = useFormState();

    const vehicle = state.vehicleData[currentIndex];

    const formRef = useRef();

    const onRemove = (): void => {
        const vehicles = [...state.vehicleData];
        updateState({
            ...state,
            vehicleData: vehicles.filter((_, index) => index !== currentIndex),
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            executeValidate() {
                if (!formRef || !formRef.current) {
                    return false;
                }

                const current = formRef.current as any;
                return current.executeValidate();
            },

            getValues() {
                if (!formRef || !formRef.current) {
                    return false;
                }

                const current = formRef.current as any;
                return current.getValues();
            },
        }),
        [formRef]
    );

    return (
        <div>
            {!vehicle.completedFinCheck ? (
                <FinCheckView
                    currentPage={Page.VehicleDataView}
                    nextPage={() => undefined}
                    previousPage={() => undefined}
                    setSubmitParameters={() => undefined}
                    currentIndex={currentIndex}
                />
            ) : (
                <VehicleDataVehicleForm
                    ref={formRef}
                    currentIndex={currentIndex}
                    poweredByOptions={poweredByOptions}
                    leasingCompanyOptions={leasingCompanyOptions}
                />
            )}

            {(state.vehicleData.length > 1 || vehicle.completedFinCheck) && (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                    <Button
                        id='remove-vehicle-button'
                        className='padded-control add-vehicle-button'
                        onClick={onRemove}
                    >
                        <Typography>
                            {'Dieses Fahrzeug entfernen '}
                            <MinusCircleFilled />
                        </Typography>
                    </Button>
                </div>
            )}
        </div>
    );
});
