import { ReactNode, useMemo, useState } from 'react';
import { Validation, performValidation } from './validator';

type FormParameters<T, U extends keyof T> = {
    initialValues: T;
    validators?: Validators<T, U> | undefined;
    onSuccess?: () => void | Promise<void>;
    onFailure?: (invalid: ErrorState<T>) => void | Promise<void>;
    onValidateFailure?: (invalid: ErrorState<T>) => void | Promise<void>;
};

export type ErrorState<T> = {
    [key in keyof T]?: ReactNode;
};

type Validators<T, U extends keyof T> = {
    [key in keyof T]?: Validation<T, U> | undefined;
};

type Form<T, U extends keyof T> = {
    values: T;
    errors: ErrorState<T>;
    setValue: (key: U, value: T[U]) => void;
    setValues: (values: Partial<T>) => void;
    validate: () => boolean;
    submit: () => void;
    resetValues: () => void;
};

export const useForm = <T, U extends keyof T>(parameters: FormParameters<T, U>): Form<T, U> => {
    const [values, setValues] = useState<T>(parameters.initialValues);
    const [errors, setErrors] = useState<ErrorState<T>>({});

    const validate = (): boolean => {
        if (parameters.validators == null) {
            return true;
        }

        let result = true;
        const newErrors: ErrorState<T> = {};
        const keys = Object.keys(parameters.validators) as U[];
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            const validator = parameters.validators[key];
            if (validator == null) {
                continue;
            }

            const value = values[key];
            let error = undefined;
            const setError = (node: ReactNode | undefined): void => {
                error = node;
                newErrors[key] = node;
            };

            performValidation(validator, value, values, setError);
            if (error != null) {
                result = false;
            }
        }

        setErrors(newErrors);
        if (!result && parameters.onValidateFailure) {
            parameters.onValidateFailure(newErrors);
        }

        return result;
    };

    const setValue = (key: U, value: T[U]): void => {
        setValues({ ...values, [key]: value });
        if (parameters == null || parameters.validators == null) {
            return;
        }

        const validator = parameters.validators[key];
        if (validator == null) {
            return;
        }

        const setError = (node: ReactNode | undefined): void => {
            setErrors({ ...errors, [key]: node });
        };

        performValidation(validator, value, values, setError);
    };

    const setValuesCallback = (newValues: Partial<T>): void => {
        setValues({ ...values, ...newValues });
        const keys = Object.keys(newValues) as U[];

        keys.forEach((key) => {
            if (parameters == null || parameters.validators == null) {
                return;
            }

            const validator = parameters.validators[key];
            if (validator == null) {
                return;
            }

            const setError = (node: ReactNode | undefined): void => {
                setErrors({ ...errors, [key]: node });
            };

            performValidation(validator, newValues[key] as T[U], values, setError);
        });
    };

    const submit = (): void => {
        if (parameters.validators == null) {
            if (parameters.onSuccess) {
                parameters.onSuccess();
            }
            return;
        }

        let result = true;
        const newErrors: ErrorState<T> = {};
        const keys = Object.keys(parameters.validators) as U[];
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            const validator = parameters.validators[key];
            if (validator == null) {
                continue;
            }

            const value = values[key];
            let error = undefined;
            const setError = (node: ReactNode | undefined): void => {
                error = node;
                newErrors[key] = node;
            };

            performValidation(validator, value, values, setError);
            if (error != null) {
                result = false;
            }
        }

        setErrors(newErrors);
        if (!result && parameters.onFailure) {
            parameters.onFailure(newErrors);
        } else if (result && parameters.onSuccess) {
            parameters.onSuccess();
        }
    };

    const returnValues = useMemo(() => values, [values]);

    return {
        values: returnValues,
        errors,
        setValue,
        setValues: setValuesCallback,
        validate,
        submit,
        resetValues: () => {
            setValues(parameters.initialValues);
            setErrors({});
        },
    };
};
