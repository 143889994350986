import { Radio, Tooltip } from 'antd';
import React, { ReactNode, forwardRef, CSSProperties } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';

type RadioItem<T> = {
    title: ReactNode;
    value: T;
    readonly?: boolean;
    infoText?: string;
};

type Props<T> = {
    value: T | undefined;
    options: RadioItem<T>[];
    readonly?: boolean | undefined;
    onChange?: (value: T | undefined) => void | Promise<void>;
    error?: ReactNode | undefined;
    containerStyle?: CSSProperties;
};

export const FormRadioGroup: React.ForwardRefExoticComponent<
    Props<unknown> & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    <T,>(
        { value, options, readonly, onChange, error, containerStyle }: Props<T>,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes = '';
        if (error != null) {
            classes += ' form-field-error';
        }

        return (
            <div className='padded-control form-field'>
                <div className={classes} ref={ref} style={containerStyle}>
                    {options.map((option, index) => {
                        return (
                            <div key={index}>
                                <Radio
                                    className='padded-control'
                                    disabled={readonly || option.readonly}
                                    checked={value === option.value}
                                    onChange={() => onChange && onChange(option.value)}
                                >
                                    <span className={classes}>{option.title}</span>
                                    {option.infoText && (
                                        <Tooltip title={option.infoText}>
                                            <QuestionCircleFilled
                                                style={{ color: '#428dcf', marginLeft: 4 }}
                                            />
                                        </Tooltip>
                                    )}
                                </Radio>
                            </div>
                        );
                    })}
                </div>
                {error && <div className={classes}>{error}</div>}
            </div>
        );
    }
);
