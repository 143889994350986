import { Divider, Typography } from 'antd';
import { useQuery } from 'lib/shared';
import { useForm } from 'lib/validation';
import { licenseNumberRegex } from 'lib/validation/regex';
import { FormDateInput, FormTextInput, NavigationContainer } from 'main-page/components';
import { DataRow } from 'main-page/components/data-row';
import React, { useEffect, useState } from 'react';
import { ViewProps } from '../view-props';
import { useApi } from 'lib/api';
import { Page } from 'main-page/pages';
import dayjs from 'dayjs';

type VehicleValues = {
    manufacture?: string | undefined;
    carName?: string | undefined;
    vin?: string | undefined;
    status?: 'open' | 'completed' | 'Not Found' | undefined;
};

type FormValues = {
    licenseNumber?: string | undefined;
    registration?: dayjs.Dayjs | null | undefined;
};

type Props = ViewProps & {
    setResultState: (value: 'exists' | 'completed' | 'Not Found') => void;
};

export const AdditionalLicenseNumberView: React.FC<Props> = ({
    nextPage,
    setResultState,
}: Props) => {
    const api = useApi();
    const linkId = useQuery().get('linkId');
    const [vehicle, setVehicle] = useState<VehicleValues>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const requestVehicleData = async (): Promise<void> => {
            try {
                const vehicleData = await api.getLicenseNumberData(linkId ?? '');
                if (vehicleData != null) {
                    if (vehicleData.status === 'open') {
                        setVehicle(vehicleData);
                    } else if (vehicleData.status === 'completed') {
                        setVehicle({});
                        setResultState('exists');
                        nextPage(Page.LicenseNumberResultView);
                    } else if (vehicleData.status === 'Not Found') {
                        setVehicle({});
                        setResultState('Not Found');
                        nextPage(Page.LicenseNumberResultView);
                    } else {
                        setVehicle({});
                        nextPage(Page.ErrorViewDefault);
                    }
                } else {
                    setVehicle({});
                    nextPage(Page.ErrorViewDefault);
                }
            } catch (e) {
                setVehicle({});
                nextPage(Page.ErrorViewDefault);
            }
        };

        requestVehicleData();
    }, []);

    const onSuccess = async (): Promise<void> => {
        try {
            setIsSubmitting(true);
            const result = await api.postAppendLicenseNumber({
                linkId: linkId || '',
                licensePlate: values.licenseNumber,
                registration: values.registration?.format('YYYY-MM-DD'),
            });
            if (result != null) {
                setResultState('completed');
                nextPage(Page.LicenseNumberResultView);
            } else {
                setVehicle({});
                nextPage(Page.ErrorViewDefault);
            }
        } catch (e) {
            setVehicle({});
            nextPage(Page.ErrorViewDefault);
        }
    };

    const { values, errors, setValue, submit } = useForm({
        initialValues: {} as FormValues,
        onSuccess: onSuccess,
        validators: {
            licenseNumber: (value) =>
                value != null &&
                value != '' &&
                licenseNumberRegex.test(value as string) ? undefined : (
                    <Typography>
                        {'Bitte ein gültiges Kennzeichen im Format W-32434I ein.'}
                    </Typography>
                ),
            registration: (value) => {
                if (!value) {
                    return <Typography>{'Bitte ein Datum angeben.'}</Typography>;
                }

                const now = dayjs().startOf('date');
                const registrationDate = dayjs(value).startOf('date');

                if (registrationDate > now) {
                    return <Typography>{'Das Datum darf nicht in der Zukunft liegen.'}</Typography>;
                }

                return undefined;
            },
        },
    });

    if (isSubmitting) {
        return (
            <div className='page'>
                <div className='view-card-top' style={{ marginLeft: 80 }}>
                    <Typography className='padded-control header'>
                        {'Ihr Kennzeichen wird übermittelt'}
                    </Typography>
                </div>
                <div className='view-card-bottom'>
                    <div className='padded-control' style={{ height: '6.5rem' }}>
                        <div className='animated-loader' />
                    </div>
                    <Typography className='padded-control centered'>
                        {
                            'Ihr Kennzeichen wird gerade übermittelt, das kann einen Augenblick dauern.'
                        }
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'Kennzeichen zu Ihrem Fahrzeug'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography className='boxed header centered'>
                    {
                        'Zur vollständigen Registrierung geben Sie bitte das Kennzeichen für das unten aufgeführte Fahrzeug ein.'
                    }
                </Typography>
                <div className='padded-control paragraph'>
                    <DataRow header={'Marke'} value={vehicle?.manufacture || ''} noDivider />
                    <DataRow header={'Modell'} value={vehicle?.carName || ''} />
                    <DataRow header={'FIN'} value={vehicle?.vin || ''} />
                    <Divider className='row-divider' />
                </div>
                <div className='paragraph'>
                    <FormTextInput
                        id='license-number'
                        label='Kennzeichen'
                        required
                        onChange={(value) => setValue('licenseNumber', value)}
                        value={values.licenseNumber}
                        error={errors.licenseNumber}
                    />
                </div>
                <div className='paragraph'>
                    <FormDateInput
                        id='registration'
                        label='Tag der Zulassung'
                        required
                        onChange={(value) => setValue('registration', value)}
                        value={values.registration}
                        error={errors.registration}
                    />
                </div>
                <NavigationContainer forwardText='Kennzeichen abschicken' forward={submit} />
            </div>
        </div>
    );
};
