export enum Page {
    ErrorViewDefault,
    ErrorViewMissingLinkId,
    LandingView,
    NeedsAnalysisView,
    CarCountChoiceView,
    MajorCustomerDataView,
    SubmitLoadingView,
    MajorCustomerResultView,
    PersonalDetailsView,
    CredibilityCheckFailedView,
    CredibilityCheckView,
    FinCheckFailedView,
    VehicleDataView,
    VehicleSummaryView,
    SummaryView,
    PaymentView,
    SubmitFailureView,
    SubmitSuccessView,
    AdditionalLicenseNumberView,
    LicenseNumberResultView,
}
