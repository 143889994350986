import { Divider, Typography } from 'antd';
import { Page } from 'main-page/pages';
import React, { useRef } from 'react';
import { IbanValidationRequestDto, useApi } from '../../../lib/api';
import { useFormState } from '../../../lib/state';
import { ErrorState, useForm } from '../../../lib/validation';
import { FormCheckboxInput, FormTextInput, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';

type FormValues = {
    iban?: string | undefined;
    bank?: string | undefined;
    sepaAccepted?: boolean | undefined;
};

export const PaymentView: React.FC<ViewProps> = ({ nextPage, previousPage }: ViewProps) => {
    const api = useApi();
    const { state, updateState } = useFormState();

    const ibanRef = useRef<HTMLDivElement>(null);
    const sepaAcceptedRef = useRef<HTMLDivElement>(null);

    const onFailure = (invalid: ErrorState<FormValues>): void => {
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (invalid.iban) {
            scrollIntoView = ibanRef;
        } else if (invalid.sepaAccepted) {
            scrollIntoView = sepaAcceptedRef;
        }

        if (scrollIntoView && scrollIntoView.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const onSuccess = (): void => {
        if (values.iban == null || values.bank == null) {
            return;
        }

        updateState({
            ...state,
            paymentDetails: {
                credibilityChecked: state.paymentDetails.credibilityChecked as boolean,
                iban: values.iban,
                bank: values.bank,
                uuid: state.paymentDetails.uuid as string,
                frequency: state.paymentDetails.frequency as 'monatlich' | 'jährlich',
                passengerAccident: state.paymentDetails.passengerAccident as boolean,
                validated: 'yes',
            },
        });
        nextPage(Page.SummaryView);
    };

    const checkIban = async (value: string | undefined): Promise<void> => {
        setValue('iban', value);
        if (state.procedureId == null || value == null || value.length < 20) {
            return;
        }

        const data: IbanValidationRequestDto = {
            procedureId: state.procedureId,
            iban: value,
        };
        const response = await api.postIbanValidation(data);
        if (response?.status == 'valid') {
            setValues({ iban: value, bank: response.bank });
        } else {
            setValues({ iban: value, bank: undefined });
        }
    };

    const { values, errors, setValue, setValues, submit } = useForm({
        initialValues: {} as FormValues,
        onFailure: onFailure,
        onSuccess: onSuccess,
        validators: {
            iban: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte eine IBAN eintragen.'}</Typography>
                ),
            sepaAccepted: (value) =>
                value != null && value ? undefined : (
                    <Typography>{'Bitte akzeptieren Sie das SEPA-Mandat.'}</Typography>
                ),
        },
    });

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'Zahlung per SEPA-Lastschrift'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography className='padded-control header'>{'Zahlungsdaten'}</Typography>
                <Divider
                    style={{
                        borderColor: 'rgba(0, 0, 0, 0.75)',
                        margin: '0 1rem',
                        width: ' auto',
                        minWidth: 'auto',
                    }}
                />
                <FormTextInput
                    id='iban'
                    label='IBAN'
                    ref={ibanRef}
                    required
                    onChange={checkIban}
                    value={values.iban}
                    error={errors.iban}
                />
                <FormTextInput id='bank' label='Bank' required readonly value={values.bank} />
                <Typography className='padded-control text-paragraph'>
                    {'Der Versicherungsnehmer muss auch Kontoinhaber sein.'}
                </Typography>

                <Typography className='padded-control header' style={{ marginBottom: '0.25rem' }}>
                    {'SEPA-Lastschriftmandat'}
                </Typography>
                <FormCheckboxInput
                    id={'sepa-allowed'}
                    label='Ich ermächtige die WIENER STÄDTISCHE Versicherung AG Vienna Insurance Group, Zahlungen von meinem Konto mittels SEPA-Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Wiener Städtischen auf mein Konto gezogenen SEPA-Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.'
                    ref={sepaAcceptedRef}
                    required
                    onChange={(value) => setValue('sepaAccepted', value)}
                    value={values.sepaAccepted}
                    error={errors.sepaAccepted}
                />
                <div className='padded-control' style={{ marginTop: '2rem' }}>
                    <Typography>
                        <b>{'Zahlungsempfänger'}</b>
                    </Typography>
                    <Typography>
                        {'WIENER STÄDTISCHE Versicherung AG Vienna Insurance Group'}
                    </Typography>
                    <Typography>{'Schottenring 30, 1010 Wien'}</Typography>
                    <Typography>
                        {'registriert beim Handelsgericht Wien unter FN 333376i, DVR 4001506'}
                    </Typography>
                    <Typography>{'Creditor-ID AT18ZZZ00000003104'}</Typography>
                </div>
                <NavigationContainer
                    forwardText='Übernehmen'
                    forward={submit}
                    backwardText='Zurück'
                    backward={previousPage}
                />
            </div>
        </div>
    );
};
