import { FilePdfOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { CSSProperties } from 'react';
import Link from 'antd/es/typography/Link';

type Props = {
    className?: string | undefined;
    style?: CSSProperties | undefined;
    title: string;
    filePath: string;
    onClick?: () => void;
    onRender?: () => React.ReactElement;
};

export const DocumentLink: React.FC<Props> = ({
    className,
    style,
    title,
    filePath,
    onClick,
    onRender,
}: Props) => {
    const styles: CSSProperties = {
        marginLeft: '0.75rem',
        ...style,
    };

    const openDocument = (): void => {
        if (onClick) {
            onClick();
        }

        window.open(filePath, '_blank');
    };

    return (
        <Link className={className} style={{ textDecoration: 'none' }} onClick={openDocument}>
            <Typography className='blue' style={styles}>
                {onRender && onRender()}
                <FilePdfOutlined style={{ color: '#0083a9', fontSize: '1.2rem' }} />
                <b>{` ${title}`}</b>
            </Typography>
        </Link>
    );
};
