import { Typography } from 'antd';
import { Page } from 'main-page/pages';
import React, { useRef } from 'react';
import { MajorCustomerRequestDto, useApi } from '../../../lib/api';
import { useFormState } from '../../../lib/state';
import { ErrorState, isEmail, useForm } from '../../../lib/validation';
import { FormTextInput, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';
import './major-customer-data-view.scss';
import { phoneNumberRegexInverted } from '../../../lib/validation/regex';

type FormValues = {
    name?: string | undefined;
    email?: string | undefined;
    phonenumber?: string | undefined;
};

export const MajorCustomerDataView: React.FC<ViewProps> = ({
    nextPage,
    setSubmitParameters,
}: ViewProps) => {
    const api = useApi();
    const { state: formState } = useFormState();
    const { procedureId } = formState;

    const emailRef = useRef<HTMLDivElement>(null);
    const nameRef = useRef<HTMLDivElement>(null);

    const onFailure = (invalid: ErrorState<FormValues>): void => {
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (invalid.name) {
            scrollIntoView = nameRef;
        } else if (invalid.email) {
            scrollIntoView = emailRef;
        }

        if (scrollIntoView && scrollIntoView.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const submit = async (): Promise<boolean> => {
        if (procedureId == null || values.name == null || values.email == null) {
            return false;
        }

        const data: MajorCustomerRequestDto = {
            procedureId: procedureId,
            name: values.name,
            email: values.email,
            phonenumber: values.phonenumber,
        };
        const result = await api.postMajorCustomerOffer(data);
        if (result?.status == 'completed') {
            return true;
        } else {
            return false;
        }
    };

    const onSuccess = (): void => {
        if (!setSubmitParameters) {
            nextPage(Page.ErrorViewDefault);
            return;
        }

        setSubmitParameters({
            longRunningAction: submit,
            successRedirect: Page.MajorCustomerResultView,
            failureRedirect: Page.ErrorViewDefault,
        });

        nextPage(Page.SubmitLoadingView);
    };

    const {
        values,
        errors,
        setValue,
        submit: onSubmit,
    } = useForm({
        initialValues: {} as FormValues,
        onFailure: onFailure,
        onSuccess: onSuccess,
        validators: {
            name: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihren Namen an.'}</Typography>
                ),
            email: (value) =>
                isEmail(value) ? undefined : (
                    <Typography>{'Bitte geben Sie eine gültige E-Mail Adresse an.'}</Typography>
                ),
        },
    });

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Großkundenangebot'}</Typography>
            </div>
            <div className='view-card'>
                <div className='boxed text-paragraph'>
                    <Typography className='padded-control paragraph'>
                        {
                            'Ab einem Fuhrpark von über 6 Taxifahrzeugen möchten wir Ihnen gerne ein auf Sie zugeschnittenes Angebot anbieten.'
                        }
                    </Typography>
                    <Typography className='padded-control paragraph'>
                        {
                            'Füllen Sie hierzu einfach das Kontaktformular aus und Ihr persönlicher Ansprechpartner meldet sich bei Ihnen.'
                        }
                    </Typography>
                    <Typography className='padded-control paragraph'>
                        {'Wir freuen uns auf Sie!'}
                    </Typography>
                </div>
            </div>
            <div className='view-card-bottom'>
                <div className='text-paragraph'>
                    <Typography className='padded-control header'>{'Kontaktformular'}</Typography>
                </div>
                <div className='text-paragraph'>
                    <FormTextInput
                        id='name'
                        label='Vor- und Nachname'
                        required
                        onChange={(value) => setValue('name', value)}
                        value={values.name}
                        error={errors.name}
                    />
                    <FormTextInput
                        id='email'
                        label='E-Mail'
                        required
                        onChange={(value) => setValue('email', value)}
                        value={values.email}
                        error={errors.email}
                    />
                    <FormTextInput
                        id='phone-number'
                        label='Telefonnummer'
                        onChange={(value) => {
                            setValue('phonenumber', value?.replace(phoneNumberRegexInverted, ''));
                        }}
                        value={values.phonenumber}
                        error={errors.phonenumber}
                    />
                </div>
                <NavigationContainer forwardText='Anfrage abschicken' forward={onSubmit} />
            </div>
        </div>
    );
};
