import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { apiKey, baseUrl } from '../../environment';

function configureAxiosGlobalSettings(): void {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.common['Authorization'] = `${apiKey}`;
}

export function createAxiosInstance(): AxiosInstance {
    configureAxiosGlobalSettings();

    const configuration: AxiosRequestConfig = {
        timeout: 0,
    };

    configuration.baseURL = baseUrl;

    return axios.create(configuration);
}
