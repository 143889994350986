import { Typography } from 'antd';
import { FormRadioGroup } from 'main-page/components/form-fields/form-radio-group';
import { Page } from 'main-page/pages';
import React, { useState } from 'react';
import { FinCheckRequestDto, useApi } from '../../../lib/api';
import { useFormState } from '../../../lib/state';
import { isAlphaNumeric, useForm } from '../../../lib/validation';
import { FormTextInput, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';
import './fin-check-view.scss';

const tooltip = (
    <div>
        <div>
            {
                'Die FIN ist die international genormte, 17-stellige Seriennummer mit der ein Kraftfahrzeug eindeutig identifizierbar ist.'
            }
        </div>
        <div>
            {
                'Ihre FIN finden Sie im Zulassungsschein oder Übereinstimmungsbescheinigung (Coc). Außerdem befindet sich die Nummer am Fahrzeug selbst, meist im Motorraum oder an der rechten Fahrzeughälfte.'
            }
        </div>
    </div>
);

const changeInsurerOptions = [
    {
        title: (
            <>
                <div className='radio-grid'>
                    <b>{'Ja'}</b>
                    <Typography>{' (Ich möchte den Versicherer wechseln.)'}</Typography>
                </div>
                <div className='hint'>
                    Wenn das Fahrzeug bereits zugelassen ist, schreiben Sie uns eine Email
                    kfz-vertrag.wien@aon-austria.at
                </div>
            </>
        ),
        value: true,
    },
    {
        title: (
            <div className='radio-grid'>
                <b>{'Nein'}</b>
                <Typography>
                    {' (Ich möchte das Fahrzeug neu versichern und auf mich zulassen.)'}
                </Typography>
            </div>
        ),
        value: false,
    },
];

type FormValues = {
    vin?: string | undefined;
    changeInsurer?: boolean | undefined;
};

type Props = ViewProps & {
    currentIndex: number;
};

export const FinCheckView: React.FC<Props> = ({ nextPage, currentIndex }: Props) => {
    const api = useApi();
    const { state, updateState } = useFormState();
    const [loading, setLoading] = useState<boolean>(false);

    const onSuccess = async (): Promise<void> => {
        if (state.procedureId == null || values.vin == null) {
            nextPage(Page.FinCheckFailedView);
            return;
        }

        let result = false;

        const data: FinCheckRequestDto = {
            procedureId: state.procedureId,
            vin: values.vin,
        };
        setLoading(true);
        const response = await api.postFinCheck(data);
        setLoading(false);
        if (response?.status == 'found') {
            const vehicles = state.vehicleData;
            vehicles[currentIndex] = {
                ...response.carData,
                changeInsurer: false, //values.changeInsurer,
                vin: values.vin,
                completedFinCheck: true,
            };
            updateState({ ...state, vehicleData: vehicles });
            result = true;
        }

        if (!result) {
            nextPage(Page.FinCheckFailedView);
        }
    };

    const { values, errors, setValue, submit } = useForm({
        initialValues: {
            changeInsurer: false,
        } as FormValues,
        onSuccess: onSuccess,
        validators: {
            vin: (value) =>
                value != null && String(value).length === 17 ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre 17-stellige FIN an.'}</Typography>
                ),
        },
    });

    return (
        <div className='page'>
            <div className='view-card'>
                <Typography className='padded-control text-paragraph'>{`Taxifahrzeug ${
                    currentIndex + 1
                }`}</Typography>
                <Typography className='padded-control' style={{ fontWeight: '600' }}>
                    {'Ist das Fahrzeug bereits auf Sie in Österreich zugelassen?'}
                </Typography>
                <FormRadioGroup
                    options={changeInsurerOptions}
                    onChange={(value) => setValue('changeInsurer', value as boolean)}
                    value={false /*values.changeInsurer*/}
                    error={errors.changeInsurer}
                    readonly
                />
            </div>
            <div>
                <FormTextInput
                    id='vehicleIdentificationNumber'
                    label='FIN (Fahrzeug-Identifikationsnummer)'
                    required
                    onChange={(value) => {
                        if (value != null && (value?.length > 17 || !isAlphaNumeric(value))) {
                            return;
                        }

                        setValue('vin', value?.toUpperCase());
                    }}
                    value={values.vin}
                    error={errors.vin}
                    tooltip={tooltip}
                />
                <NavigationContainer forwardText='FIN-Check' forward={submit} isLoading={loading} />
            </div>
        </div>
    );
};
