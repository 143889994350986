import React, { ReactNode } from 'react';

type Validator<T, U extends keyof T> = (value: T[U], otherValues: T) => ReactNode;

export type Validation<T, U extends keyof T> = Validator<T, U> | Validator<T, U>[];

export const performValidation = <T, U extends keyof T>(
    validation: Validation<T, U>,
    newValue: T[U],
    state: T,
    setError: (error: ReactNode) => void
): boolean => {
    if (validation) {
        if (Array.isArray(validation)) {
            const results = validation.map((validator) => validator(newValue, state));
            if (results.some((message) => message != null)) {
                setError(<>{results}</>);
                return false;
            } else {
                setError(undefined);
                return true;
            }
        } else {
            const error = validation(newValue, state);
            setError(error);
            return error == null;
        }
    } else {
        setError(undefined);
        return true;
    }
};
