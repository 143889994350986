import { ConfigProvider } from 'antd';
import de from 'antd/locale/de_DE';
import 'dayjs/locale/de';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { AxiosProvider } from './lib/axios';
import { FormStateProvider } from './lib/state';

export const AppWithProviders = (): React.ReactElement => {
    return (
        <AxiosProvider>
            <FormStateProvider>
                <BrowserRouter>
                    <ConfigProvider locale={de}>
                        <App />
                    </ConfigProvider>
                </BrowserRouter>
            </FormStateProvider>
        </AxiosProvider>
    );
};

export default AppWithProviders;
