export const formatAsCurrency = (value: number | string | undefined): string => {
    if (value === null || value === undefined) {
        return '0 €';
    }

    const formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
    if (typeof value === 'string') {
        return formatter.format(Number(value.replaceAll('.', '').replaceAll(',', '.')));
    } else {
        return formatter.format(value);
    }
};
