import { Button, Typography } from 'antd';
import React from 'react';
import './navigation-container.scss';

type Props = {
    forwardText: string;
    backwardText?: string | undefined;
    isLoading?: boolean | undefined;
    beforeForward?: () => boolean | void;
    forward: () => void;
    afterForward?: () => void;
    beforeBackward?: () => boolean | void;
    backward?: () => void;
    afterBackward?: () => void;
};

export const NavigationContainer: React.FC<Props> = ({
    forwardText,
    backwardText,
    isLoading,
    beforeForward,
    forward,
    afterForward,
    beforeBackward,
    backward,
    afterBackward,
}: Props) => {
    const onForwardClick = (): void => {
        beforeForward && beforeForward();
        forward();
        afterForward && afterForward();
    };

    const onBackwardClick = (): void => {
        beforeBackward && beforeBackward();
        backward && backward();
        afterBackward && afterBackward();
    };

    return (
        <div className='navigation-container'>
            <Button
                id='navigation-button-next'
                className='padded-control navigation-button-next'
                loading={isLoading}
                onClick={onForwardClick}
            >
                <Typography>{`${forwardText} »`}</Typography>
            </Button>
            {backward && (
                <Button
                    id='navigation-button-previous'
                    className='padded-control navigation-button-previous'
                    onClick={onBackwardClick}
                >
                    <Typography>{`« ${backwardText}`}</Typography>
                </Button>
            )}
        </div>
    );
};
