import { DatePicker, Typography } from 'antd';
import de from 'antd/es/date-picker/locale/de_DE';
//import deDE from 'antd/es/locale/de_DE';
import dayjs from 'dayjs';
import React, { ReactNode, forwardRef } from 'react';
import { dateFormat } from '../../../lib/shared/date-format';
import './form-field.scss';

type Props = {
    id: string;
    label: string;
    value: dayjs.Dayjs | null | undefined;
    required?: boolean | undefined;
    readonly?: boolean | undefined;
    onChange?: (value: dayjs.Dayjs | null | undefined) => void | Promise<void>;
    error?: ReactNode | undefined;
};

export const FormDateInput: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        { id, label, value, required, readonly, onChange, error }: Props,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes = '';
        if (error != null) {
            classes += ' form-field-error';
        }

        const changeValue = (newValue: dayjs.Dayjs | string | null): void => {
            if (onChange == null) {
                return;
            }

            if (typeof newValue === 'string') {
                newValue = newValue.replaceAll('.', '');
                if (newValue.length != 8) {
                    return;
                }
                newValue = newValue.substring(0, 2) + '.' + newValue.substring(2);
                newValue = newValue.substring(0, 5) + '.' + newValue.substring(5);
                const date: dayjs.Dayjs = dayjs(newValue, dateFormat);
                if (!date.isValid()) {
                    return;
                }
                const offset = date?.utcOffset();
                if (offset == null) {
                    onChange(date);
                } else {
                    onChange(date?.add(offset, 'minute'));
                }
            } else {
                if (!newValue?.isValid()) {
                    return;
                }
                const offset = newValue?.utcOffset();
                if (offset == null) {
                    onChange(newValue);
                } else {
                    onChange(newValue?.add(offset, 'minute'));
                }
            }
        };

        return (
            <div className='padded-control form-field'>
                <div className={`form-field-label ${classes}`} ref={ref}>
                    <Typography>{label}</Typography>
                    {required && <Typography className='form-field-required'>*</Typography>}
                </div>
                <div className={classes}>
                    <DatePicker
                        size='large'
                        id={id}
                        className='form-field-input'
                        value={value}
                        disabled={readonly}
                        placeholder='DD.MM.YYYY'
                        locale={de}
                        format={dateFormat}
                        onBlur={(e) => changeValue(e?.target?.value)}
                        onChange={changeValue}
                        status={error ? 'error' : ''}
                    />
                </div>
                {error && <div className={classes}>{error}</div>}
            </div>
        );
    }
);
