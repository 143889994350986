import { Button, Typography } from 'antd';
import { Page } from 'main-page/pages';
import React from 'react';
import { ViewProps } from '../view-props';
import './car-count-choice-view.scss';

export const CarCountChoiceView: React.FC<ViewProps> = ({ nextPage }: ViewProps) => {
    const onUpTo6Click = (): void => {
        nextPage(Page.PersonalDetailsView);
    };

    const onMoreThan6Click = (): void => {
        nextPage(Page.MajorCustomerDataView);
    };

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'In wenigen Schritten zu Ihrer Autoversicherung.'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <div style={{ marginBottom: '2rem' }}>
                    <Typography className='padded-control' style={{ fontSize: '18px' }}>
                        {'Wie viele Taxifahrzeuge möchten Sie versichern?'}
                    </Typography>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr', marginBottom: '2rem' }}>
                    <Button className='padded-control car-choice-button' onClick={onUpTo6Click}>
                        <b>{'1-6 '}</b>
                        {' Taxifahrzeuge'}
                    </Button>
                    <Button className='padded-control car-choice-button' onClick={onMoreThan6Click}>
                        <b>{'über 6 '}</b>
                        {' Taxifahrzeuge'}
                    </Button>
                </div>
            </div>
            <div className='view-card-bottom' style={{ background: 'transparent' }} />
        </div>
    );
};
