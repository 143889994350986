import React, { ReactElement } from 'react';

interface Props {
    question: string;
    answer: string;
}

export const NeedsAnalysisAnswer = ({ question, answer }: Props): ReactElement => {
    return (
        <div style={{ marginBottom: 30 }}>
            <div>{question}</div>
            <div style={{ fontWeight: 'bold', marginTop: 8, marginLeft: 18 }}>{answer}</div>
        </div>
    );
};
