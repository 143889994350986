import { useFormState } from 'lib/state';
import React from 'react';
import './main-page.scss';
import { Page } from './pages';
import { ViewProps } from './views/view-props';

type Props = ViewProps & {
    landingPage?: Page | undefined;
};

export const AppHeader: React.FC<Props> = ({ nextPage, landingPage }: Props) => {
    const { resetState } = useFormState();

    const gotoLandingPage = (): void => {
        resetState();
        if (!landingPage) {
            nextPage(Page.LandingView, false);
        } else {
            nextPage(landingPage, false);
        }
    };

    return (
        <div className='app-header'>
            <img
                alt='AON'
                src='/static/images/logo-aon.png'
                height={80}
                onClick={gotoLandingPage}
            />
        </div>
    );
};
