export const isNumber = (value: string): boolean => {
    return !value || !!Number(value) || /^0*$/.test(value);
};

export const isLetter = (value: string): boolean => {
    for (let i = 0; i < value.length; ++i)
        if (value[i].toLowerCase() === value[i].toUpperCase()) return false;
    return true;
};

export const isAlphaNumeric = (value: string): boolean => {
    for (let i = 0; i < value.length; ++i)
        if (!isNumber(value[i]) && !isLetter(value[i])) return false;
    return true;
};
