import { QuestionCircleFilled } from '@ant-design/icons';
import { Input, Tooltip, Typography } from 'antd';
import React, { ReactNode, forwardRef } from 'react';
import './form-field.scss';

const integerRegex = /^\d+$/;
const decimalRegex = /^(\d{1,3}\.?)*,?\d{0,2}$/;

type Props = {
    id: string;
    label: string;
    value: string | undefined;
    required?: boolean | undefined;
    readonly?: boolean | undefined;
    allowDecimals?: boolean | undefined;
    withThousandSeperators?: boolean | undefined;
    unit?: string | undefined;
    onChange?: (value: string | undefined) => void | Promise<void>;
    error?: ReactNode | undefined;
    tooltip?: ReactNode | undefined;
};

export const FormNumberInput: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        {
            id,
            label,
            value,
            required,
            readonly,
            allowDecimals,
            withThousandSeperators,
            unit,
            onChange,
            error,
            tooltip,
        }: Props,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes = '';
        if (error != null) {
            classes += ' form-field-error';
        }

        const format = (value: string): string => {
            const rawNumber = Number(value.replaceAll('.', '').replaceAll(',', '.'));
            if (withThousandSeperators) {
                return rawNumber.toLocaleString('de-DE');
            } else {
                return allowDecimals
                    ? rawNumber.toFixed(2).replaceAll('.', ',')
                    : rawNumber.toFixed(0);
            }
        };

        const change = (value: string): void => {
            if (!onChange) {
                return;
            }

            if (allowDecimals && decimalRegex.test(value)) {
                onChange(format(value));
            } else if (!allowDecimals && integerRegex.test(value)) {
                onChange(format(value));
            }
        };

        return (
            <div className='padded-control form-field'>
                <div className={`form-field-label ${classes}`} ref={ref}>
                    <Typography>{label}</Typography>
                    {required && <Typography className='form-field-required'>*</Typography>}
                    {tooltip && (
                        <>
                            <div style={{ marginLeft: '1rem' }} />
                            <Tooltip title={tooltip} color='#0083a9'>
                                <QuestionCircleFilled style={{ color: '#0083a9' }} />
                            </Tooltip>
                        </>
                    )}
                </div>
                <div className={classes}>
                    <span>
                        <Input
                            size='large'
                            id={id}
                            className={unit ? 'form-field-input-with-unit' : 'form-field-input'}
                            value={value}
                            readOnly={readonly}
                            disabled={readonly}
                            onChange={(event) => change(event.target.value)}
                            status={error ? 'error' : ''}
                        />
                        {unit && <span>{unit}</span>}
                    </span>
                </div>
                {error && <div className={classes}>{error}</div>}
            </div>
        );
    }
);
