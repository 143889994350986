import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LicenseNumberPage, MainPage } from '../main-page';
import { Routing } from './routing';

export const RoutedContent = (): React.ReactElement | null => {
    return (
        <Routes>
            <Route path={Routing.main} element={<MainPage />} />
            <Route path={Routing.licenseNumber} element={<LicenseNumberPage />} />

            <Route path='*' element={<Navigate to={Routing.main} replace />} />
        </Routes>
    );
};
