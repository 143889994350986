import { Typography } from 'antd';
import { Page } from 'main-page/pages';
import React, { useEffect } from 'react';
import { ViewProps } from '../view-props';
import './submit-loading-view.scss';

export type SubmitLoadingProps = {
    longRunningAction: () => boolean | Promise<boolean>;
    successRedirect: Page;
    failureRedirect: Page;
};

type Props = ViewProps & SubmitLoadingProps;

export const SubmitLoadingView: React.FC<Props> = ({
    nextPage,
    longRunningAction,
    successRedirect,
    failureRedirect,
}: Props) => {
    useEffect(() => {
        const action = async (): Promise<void> => {
            const result = await longRunningAction();
            if (result) {
                nextPage(successRedirect, false);
            } else {
                nextPage(failureRedirect, false);
            }
        };

        action();
    }, []);

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'Ihr Antrag wird übermittelt'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <div className='padded-control' style={{ height: '6.5rem' }}>
                    <div className='animated-loader' />
                </div>
                <Typography className='padded-control centered'>
                    {'Ihr Antrag wird gerade übermittelt, das kann einen Augenblick dauern.'}
                </Typography>
            </div>
        </div>
    );
};
