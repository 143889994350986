import { QuestionCircleFilled } from '@ant-design/icons';
import { Input, Tooltip, Typography } from 'antd';
import React, { ReactNode, forwardRef } from 'react';
import './form-field.scss';

type Props = {
    id: string;
    label: string;
    value: string | undefined;
    required?: boolean | undefined;
    readonly?: boolean | undefined;
    onChange?: (value: string | undefined) => void | Promise<void>;
    error?: ReactNode | undefined;
    tooltip?: ReactNode | undefined;
    maxLength?: number | undefined;
};

export const FormTextInput: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        { id, label, value, required, readonly, onChange, error, tooltip, maxLength }: Props,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes = '';
        if (error != null) {
            classes += ' form-field-error';
        }

        return (
            <div className='padded-control form-field'>
                <div className={`form-field-label ${classes}`} ref={ref}>
                    <Typography>{label}</Typography>
                    {required && <Typography className='form-field-required'>*</Typography>}
                    {tooltip && (
                        <>
                            <div style={{ marginLeft: '1rem' }} />
                            <Tooltip title={tooltip} color='#0083a9'>
                                <QuestionCircleFilled style={{ color: '#0083a9' }} />
                            </Tooltip>
                        </>
                    )}
                </div>
                <div className={classes}>
                    <Input
                        size='large'
                        id={id}
                        className={'form-field-input'}
                        value={value}
                        readOnly={readonly}
                        disabled={readonly}
                        maxLength={maxLength}
                        onChange={(event) => {
                            onChange && onChange(event.target.value);
                        }}
                        status={error ? 'error' : ''}
                    />
                </div>
                {error && <div className={classes}>{error}</div>}
            </div>
        );
    }
);
