import { ExclamationCircleFilled, PlusCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Button, Divider, Tooltip, Typography, notification, Modal } from 'antd';
import Link from 'antd/es/typography/Link';
import { PaymentDetailsState } from 'lib/state/payment-details-state';
import { FormRadioGroup } from 'main-page/components/form-fields/form-radio-group';
import { Page } from 'main-page/pages';
import React, { useEffect, useRef, useState } from 'react';
import { InsuranceRateRequestDto, useApi } from '../../../lib/api';
import { VehicleDataState, useFormState } from '../../../lib/state';
import { useForm } from '../../../lib/validation';
import { DocumentLink, Documents, FormSelectInput, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';
import { VehicleController } from './vehicle-controller';
import './vehicle-data-view.scss';

const passengerAccidentTooltip =
    'Finanzielle Vorsorge für den Fall, dass Sie oder Ihre Insassen bei einem Unfall getötet oder verletzt werden.';

const passengerAccidentOptions = [
    {
        title: (
            <Typography>
                <b>{'Ja'}</b>
                {', ich möchte einen Kfz-Lenkerunfall-Schutz.'}
            </Typography>
        ),
        value: true,
    },
    {
        title: (
            <Typography>
                <b>{'Nein'}</b>
                {', ich möchte keinen Kfz-Lenkerunfall-Schutz.'}
            </Typography>
        ),
        value: false,
    },
];

type Props = ViewProps & {
    currentIndex: number;
    navigateToNeedsAnalysis: () => void;
};

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const VehicleDataView: React.FC<Props> = ({
    nextPage,
    previousPage,
    currentIndex,
    navigateToNeedsAnalysis,
}: Props) => {
    const api = useApi();
    const { state, updateState } = useFormState();
    const [poweredByOptions, setPoweredByOptions] = useState<string[]>([]);
    const [leasingCompanyOptions, setLeasingCompanyOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isValid, setValid] = useState<boolean>(true);
    const [notify, notifyContext] = notification.useNotification();
    const [isChangeProtectionOpen, setIsChangeProtectionOpen] = useState(false);

    const formRefs = useRef([]);

    useEffect(() => {
        const requestOptions = async (): Promise<void> => {
            try {
                const data = await api.getPoweredByPrefillings();
                setPoweredByOptions(data?.engineTypeList ?? []);
                setLeasingCompanyOptions(data?.leasingCompanyList ?? []);
            } catch {
                setPoweredByOptions([]);
                setLeasingCompanyOptions([]);
            }
        };

        void requestOptions();
    }, []);

    const openToastNotification = (
        type: NotificationType,
        title: string,
        message: string
    ): void => {
        notify[type]({
            message: title,
            description: message,
            placement: 'bottomRight',
            duration: 0,
        });
    };

    const sendRequest = async (
        vehicleValues: VehicleDataState
    ): Promise<VehicleDataState | null> => {
        if (state.procedureId == null) {
            return null;
        }

        const data: InsuranceRateRequestDto = {
            procedureId: state.procedureId,
            uuid: state.paymentDetails.uuid as string,
            paymentMethod: values.frequency as string,
            passengerAccident: !!values.passengerAccident,
            carData: {
                ...vehicleValues,
                firstRegistration: vehicleValues.firstRegistration?.format('YYYY-MM-DD'),
                grossListPrice: Number(vehicleValues.grossListPrice?.replaceAll('.', '')),
                optionalEquipment: Number(vehicleValues.optionalEquipment?.replaceAll('.', '')),
            },
        };
        setLoading(true);
        const response = await api.postInsuranceRate(data);
        setLoading(false);
        if (response == null || response.status == 'ERROR') {
            openToastNotification(
                'error',
                '',
                `Hoppla, es gab ein Problem bei der Tarifierung bei dem Fahrzeug mit der FIN ${vehicleValues.vin}. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.`
            );
            return null;
        }

        const carData: VehicleDataState = {
            ...vehicleValues,
            rate: {
                rateId: response.rateId,
            },
        };
        if (response.rate != null && response.rate.length > 0 && carData.rate != null) {
            carData.rate.components = [...response.rate];
        }

        return carData;
    };

    const addAnother = (): void => {
        const vehicles = [...state.vehicleData];
        vehicles.push({ completedFinCheck: false, changeInsurer: false });
        updateState({ ...state, vehicleData: vehicles });
    };

    const onSubmit = async (): Promise<void> => {
        const vehicleData = [];
        let anyValidationFailed = false;

        if (!validate()) {
            anyValidationFailed = true;
        }

        if (formRefs && formRefs.current) {
            const current = formRefs.current as any;
            for (let i = 0; i < current.length; ++i) {
                const component = current[i];
                if (!component) {
                    continue;
                }
                if (!component.executeValidate()) {
                    anyValidationFailed = true;
                }

                vehicleData.push(component.getValues());
            }
        }

        setValid(!anyValidationFailed);
        if (anyValidationFailed) {
            return;
        }

        const paymentDetails: PaymentDetailsState = {
            ...state.paymentDetails,
            passengerAccident: values.passengerAccident,
            frequency: values.frequency,
            validated: 'no',
        };

        const updatedVehicleData: VehicleDataState[] = [];
        let anyRequestFailed = false;
        for (let i = 0; i < vehicleData.length; ++i) {
            const newData = await sendRequest(vehicleData[i]);
            if (!newData) {
                anyRequestFailed = true;
                continue;
            }

            updatedVehicleData.push(newData);
        }

        if (!anyRequestFailed) {
            updateState({
                ...state,
                paymentDetails: paymentDetails,
                vehicleData: updatedVehicleData,
            });
            nextPage(Page.VehicleSummaryView);
        }
    };

    const { values, errors, validate, setValue } = useForm({
        initialValues: state.paymentDetails || {},
        validators: {
            passengerAccident: (value) =>
                value != null ? undefined : (
                    <Typography>{'Bitte die gewünschte Option auswählen.'}</Typography>
                ),
            frequency: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte die erwünschte Zahlfrequenz angeben.'}</Typography>
                ),
        },
    });

    useEffect(() => {
        if (state.vehicleData.length < 1) {
            addAnother();
        }
    }, [state.vehicleData.length]);

    useEffect(() => {
        const hasPersonalProtection = state.needAnalysis.protectedPeople === 'Mich';
        setValue('passengerAccident', hasPersonalProtection);
    }, [state.needAnalysis]);

    return (
        <>
            <div className='page'>
                {notifyContext}
                <div className='view-card-top'>
                    <Typography className='padded-control header'>
                        {'Welche Fahrzeuge möchten Sie versichern?'}
                    </Typography>
                </div>
                <div className='view-card'>
                    <Typography className='padded-control header'>{'Angaben Fahrzeuge'}</Typography>
                    <Divider style={{ color: 'black' }} />

                    {state.vehicleData.map((vehicle, index) => (
                        <div key={vehicle.vin}>
                            <VehicleController
                                ref={(element) => ((formRefs.current as any)[index] = element)}
                                currentIndex={index}
                                poweredByOptions={poweredByOptions}
                                leasingCompanyOptions={leasingCompanyOptions}
                            />
                            <Divider style={{ color: 'black' }} />
                        </div>
                    ))}

                    {currentIndex < 5 && state.vehicleData.every((v) => v.completedFinCheck) && (
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                            <Button
                                id='add-vehicle-button'
                                className='padded-control add-vehicle-button'
                                onClick={addAnother}
                                loading={loading}
                            >
                                <Typography>
                                    {'Weitere Fahrzeuge hinzufügen '}
                                    <PlusCircleFilled />
                                </Typography>
                            </Button>
                        </div>
                    )}
                </div>
                <div className='view-card'>
                    <Typography className='padded-control'>
                        {
                            'Möchten Sie sich oder die Insassen bei persönlichen Unfallfolgen durch einen Kfz-Lenkerunfall-Schutz absichern?'
                        }
                        <Tooltip title={passengerAccidentTooltip} color='#0083a9'>
                            <QuestionCircleFilled
                                style={{ color: '#0083a9', marginLeft: '0.5rem' }}
                            />
                        </Tooltip>
                    </Typography>
                    <div className='padded-control'>
                        <DocumentLink
                            title='Produktinformationsblatt Kfz-Unfallversicherung'
                            filePath={Documents.Lenkerschutz}
                        />
                    </div>
                    <div
                        className='padded-control'
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            gridTemplateRows: 'auto 1fr',
                            marginBottom: '1rem',
                        }}
                    >
                        <FormRadioGroup
                            readonly
                            options={passengerAccidentOptions}
                            onChange={(value) => setValue('passengerAccident', value as boolean)}
                            value={values.passengerAccident}
                            error={errors.passengerAccident}
                        />
                    </div>
                    <div className='padded-control'>
                        <Link onClick={() => setIsChangeProtectionOpen(true)}>
                            <Typography className='blue'>
                                <b>{`Kfz-Lenkerunfall-Schutz ändern`}</b>
                            </Typography>
                        </Link>
                    </div>
                </div>
                <div className='view-card'>
                    <FormSelectInput
                        id='payment'
                        label='Zahlungsfrequenz'
                        required
                        options={['monatlich', 'jährlich']}
                        onChange={(value) => setValue('frequency', value)}
                        value={values.frequency}
                        error={errors.frequency}
                    />
                </div>
                <div className='view-card-bottom'>
                    <div className='padded-control infobox'>
                        <ExclamationCircleFilled style={{ color: '#0083a9' }} />
                        <Typography className='infobox'>
                            {
                                'Die Berechnung erfolgt auf Basis der von Ihnen bereitgestellten Daten und Prämienauskünfte der WIENER STÄDTISCHE Versicherung AG Vienna Insurance Group.'
                            }
                            <br />
                            {
                                'Bei Angebotsannahme werden Ihre Daten von uns an die WIENER STÄDTISCHE Versicherung AG Vienne Insurance Group weitergereicht, welche Ihre Daten als eigenständiger Verantwortlicher, wie unter '
                            }
                            <Link
                                target='_blank'
                                rel='noreferrer'
                                href={
                                    'https://www.wienerstaedtische.at/datenschutz-informationen.html'
                                }
                            >
                                <u>
                                    {
                                        'https://www.wienerstaedtische.at/datenschutz-informationen.html'
                                    }
                                </u>
                            </Link>
                            {' beschrieben, verarbeitet.'}
                        </Typography>
                    </div>
                    {!isValid && (
                        <div className='padded-control errorbox'>
                            <ExclamationCircleFilled style={{ color: '#ff4d4f' }} />
                            <Typography className='errorbox'>
                                {
                                    'Es fehlen Angaben, bitte ergänzen Sie oben die rot markierten Felder.'
                                }
                            </Typography>
                        </div>
                    )}
                    <NavigationContainer
                        forwardText='Prämie berechnen'
                        forward={onSubmit}
                        isLoading={loading}
                        backwardText='Zurück'
                        backward={previousPage}
                    />
                </div>
            </div>
            <Modal
                open={isChangeProtectionOpen}
                onCancel={() => setIsChangeProtectionOpen(false)}
                footer={null}
            >
                Möchten Sie Ihren Kfz-Lenkerunfall-Schutz ändern?
                <br />
                Diese Empfehlung basiert auf Ihrer Online-Analyse, um sicherzustellen, dass Ihre
                Wünsche und Bedürfnisse erfüllt werden.
                <br />
                <br />
                Wenn sich ihre Wünsche und Bedürfnisse geändert haben, können Sie die Analyse erneut
                durchführen
                <br />
                <br />
                <div
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Link onClick={() => navigateToNeedsAnalysis()}>
                        <Typography className='blue'>
                            <b>{`Wünsche- und Bedürfnisanalyse`}</b>
                            <br />
                            <b>{` neu starten >>`}</b>
                        </Typography>
                    </Link>
                </div>
            </Modal>
        </>
    );
};
