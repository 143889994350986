import { Typography } from 'antd';
import React from 'react';
import { useFormState } from '../../../lib/state';
import { ViewProps } from '../view-props';
import { VehicleSubmitResult } from './components';

type Props = ViewProps & {
    submit: () => boolean | Promise<boolean>;
};

export const SubmitFailureView: React.FC<Props> = ({
    currentPage,
    nextPage,
    previousPage,
    setSubmitParameters,
    submit,
}: Props) => {
    const { state } = useFormState();

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Übermittlung'}</Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography
                    className='padded-control subheader centered boxed'
                    style={{ marginBottom: '2rem' }}
                >
                    {'Nicht alle Anträge wurden übermittelt. Bitte versuchen Sie es erneut.'}
                </Typography>
                {state.submitData?.map((data, index) => {
                    return (
                        <VehicleSubmitResult
                            key={data.vin}
                            details={data}
                            index={index}
                            currentPage={currentPage}
                            nextPage={nextPage}
                            previousPage={previousPage}
                            submitAgain={submit}
                            setSubmitParameters={setSubmitParameters}
                        />
                    );
                })}
            </div>
        </div>
    );
};
