import React, { ReactElement } from 'react';
import { NeedQuestion } from './need-question';
import { FormRadioGroup } from '../../components/form-fields/form-radio-group';

interface Props {
    onChange: (value: boolean | undefined) => void | Promise<void>;
    value: boolean | undefined;
    question: string;
    infoText?: string;
    answer?: string;
    changeValue: () => void;
}

export const YesNoNeedQuestion = (props: Props): ReactElement => {
    return (
        <NeedQuestion
            {...props}
            answer={props.value == null ? undefined : props.value ? 'Ja' : 'Nein'}
            renderSelection={() => (
                <FormRadioGroup
                    containerStyle={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    options={[
                        { title: 'Ja', value: true },
                        { title: 'Nein', value: false },
                    ]}
                    onChange={(value) => props.onChange(value as boolean)}
                    value={props.value}
                />
            )}
        />
    );
};
