import { useAxios } from '../axios';
import { ApiRequestClient } from './api-request-client';
import {
    CREDIT_CHECK,
    CUSTOMER_DATA,
    FIN_CHECK,
    IBAN_VALIDATION,
    INSURANCE_CONCLUSION,
    INSURANCE_RATE,
    LICENSE_PLATE_REGISTRATION,
    LICENSE_PLATE_REGISTRATION_DATA,
    MAJOR_CUSTOMER_OFFER,
    PREFILLING,
} from './api-routes';
import { CreditCheckRequestDto, CreditCheckResultDto } from './credit-check-dto';
import { CustomerDataResultDto } from './customer-data-dto';
import { FinCheckRequestDto, FinCheckResultDto } from './fin-check-dto';
import { IbanValidationRequestDto, IbanValidationResultDto } from './iban-validation-dto';
import {
    InsuranceConclusionRequestDto,
    InsuranceConclusionResultDto,
} from './insurance-conclusion-dto';
import { InsuranceRateRequestDto, InsuranceRateResultDto } from './insurance-rate-dto';
import {
    AppendLicenseNumberRequestDto,
    AppendLicenseNumberResultDto,
    LicenseNumberDataResultDto,
} from './license-number.dto';
import { MajorCustomerRequestDto, MajorCustomerResultDto } from './major-customer-dto';
import { PrefillingResultDto } from './prefilling-dto';

export const useApi = (): ApiRequestClient => {
    const axios = useAxios();

    const getCustomerData = async (
        linkId?: string | undefined
    ): Promise<CustomerDataResultDto | undefined> => {
        if (linkId == null) {
            return undefined;
        }

        try {
            const { data } = await axios.get<CustomerDataResultDto>(
                `${CUSTOMER_DATA}?linkId=${linkId}`
            );
            return data;
        } catch {
            return undefined;
        }
    };

    const getPoweredByPrefillings = async (): Promise<PrefillingResultDto | undefined> => {
        try {
            const { data } = await axios.get<PrefillingResultDto>(PREFILLING);
            return data;
        } catch {
            return undefined;
        }
    };

    const postMajorCustomerOffer = async (
        data: MajorCustomerRequestDto
    ): Promise<MajorCustomerResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<MajorCustomerResultDto>(
                MAJOR_CUSTOMER_OFFER,
                data
            );
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const postCreditCheck = async (
        data: CreditCheckRequestDto
    ): Promise<CreditCheckResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<CreditCheckResultDto>(CREDIT_CHECK, data);
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const postFinCheck = async (
        data: FinCheckRequestDto
    ): Promise<FinCheckResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<FinCheckResultDto>(FIN_CHECK, data);
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const postInsuranceRate = async (
        data: InsuranceRateRequestDto
    ): Promise<InsuranceRateResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<InsuranceRateResultDto>(INSURANCE_RATE, data);
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const postIbanValidation = async (
        data: IbanValidationRequestDto
    ): Promise<IbanValidationResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<IbanValidationResultDto>(
                IBAN_VALIDATION,
                data
            );
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const postInsuranceConclusion = async (
        data: InsuranceConclusionRequestDto
    ): Promise<InsuranceConclusionResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<InsuranceConclusionResultDto>(
                INSURANCE_CONCLUSION,
                data
            );
            return result;
        } catch (e) {
            return undefined;
        }
    };

    const getLicenseNumberData = async (
        linkId: string
    ): Promise<LicenseNumberDataResultDto | undefined> => {
        try {
            const { data } = await axios.get<LicenseNumberDataResultDto>(
                LICENSE_PLATE_REGISTRATION_DATA,
                { params: { linkId: linkId } }
            );
            return data;
        } catch {
            return undefined;
        }
    };

    const postAppendLicenseNumber = async (
        data: AppendLicenseNumberRequestDto
    ): Promise<AppendLicenseNumberResultDto | undefined> => {
        try {
            const { data: result } = await axios.post<AppendLicenseNumberResultDto>(
                LICENSE_PLATE_REGISTRATION,
                data
            );
            return result;
        } catch (e) {
            return undefined;
        }
    };

    return {
        getCustomerData,
        getPoweredByPrefillings,
        postMajorCustomerOffer,
        postCreditCheck,
        postFinCheck,
        postInsuranceRate,
        postIbanValidation,
        postInsuranceConclusion,
        getLicenseNumberData,
        postAppendLicenseNumber,
    };
};
