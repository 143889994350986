import { Typography } from 'antd';
import { useFormState } from 'lib/state';
import { NavigationContainer } from 'main-page/components';
import { Page } from 'main-page/pages';
import React, { useEffect } from 'react';
import { ViewProps } from '../view-props';

type Props = ViewProps & {
    message?: string | undefined;
    showButton?: boolean | undefined;
    landingPage?: Page | undefined;
};

export const ErrorView: React.FC<Props> = ({
    nextPage,
    message,
    showButton,
    landingPage,
}: Props) => {
    const { resetState } = useFormState();

    useEffect(() => {
        resetState();
    }, []);

    if (message == null) {
        message = 'Bitte geben Sie Ihre Angaben erneut ein und versuchen Sie es noch einmal.';
    }

    if (showButton == null) {
        showButton = true;
    }

    const onClick = (): void => {
        if (!landingPage) {
            nextPage(Page.LandingView, false);
        } else {
            nextPage(landingPage, false);
        }
    };

    return (
        <div className='page'>
            <div className='view-card-top' style={{ height: '6rem' }}></div>
            <div className='view-card-bottom'>
                <Typography className='boxed header centered'>
                    {'Ups, da ist etwas schief gelaufen.'}
                </Typography>
                <Typography className='padded-control centered'>{message}</Typography>
                {showButton && (
                    <NavigationContainer forwardText='Zurück zur Startseite' forward={onClick} />
                )}
            </div>
        </div>
    );
};
