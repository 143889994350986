import { ExclamationCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormRadioGroup } from 'main-page/components/form-fields/form-radio-group';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VehicleDataState, useFormState } from '../../../lib/state';
import { useForm } from '../../../lib/validation';
import { licenseNumberRegex, numberRegex } from '../../../lib/validation/regex';
import { FormDateInput, FormSelectInput, FormTextInput } from '../../components';
import { FormNumberInput } from '../../components/form-fields/form-number-input';
import dayjs from 'dayjs';

const grossListPriceTooltip = (
    <div>
        {
            'Für die Berechnung der Prämie ist der zum Zeitpunkt des Vertragsabschlusses gültige Fahrzeuglistenneupreis heranzuziehen. Der gültige Fahrzeuglistenneupreis ist der vom Kfz-Handel bekannt gegebene (von "Eurotax" regelmäßig veröffentlichte) unrabattierte Listenneupreis (inklusive NOVA u. MWSt.) des Fahrzeugmodells. Wird das Fahrzeugmodell nicht mehr hergestellt, ist der zuletzt gültige Listenneupreis der Berechnung zugrunde zu legen.'
        }
    </div>
);

const optionalEquipmentTooltip = (
    <div>
        {
            'Alle Ergänzungen, die Sie - nicht serienmäßig - in Ihr Auto einbauen lassen, sind hier gemäß ihrem Preis einzugeben.'
        }
    </div>
);

const leasingCompanyTooltip = (
    <div>
        {
            'Sie haben angegeben, dass es sich bei Ihrem Fahrzeug um ein Leasingfahrzeug handelt. Bitte geben Sie die Leasinggesellschaft an, damit die Kaskoversicherung zu Gunsten der Leasinggesellschaft vinkuliert (gesperrt) werden kann. Die Vinkulierung wird von den Leasinggesellschaften verlangt und dient zur Sicherstellung offener Forderungen aus dem Leasingvertrag. Eine Veränderung des Vertrages ist in Folge nur mit Zustimmung des Leasinggebers möglich.'
        }
    </div>
);

const changeInsurerOptions = [
    {
        title: (
            <div className='radio-grid'>
                <b>{'Ja'}</b>
                <Typography>{' (Ich möchte den Versicherer wechseln.)'}</Typography>
            </div>
        ),
        value: true,
    },
    {
        title: (
            <div className='radio-grid'>
                <b>{'Nein'}</b>
                <Typography>
                    {' (Ich möchte das Fahrzeug neu versichern und auf mich zulassen.)'}
                </Typography>
            </div>
        ),
        value: false,
    },
];

const leasingOptions = [
    {
        title: (
            <div className='radio-grid'>
                <b>{'Ja,'}</b>
                <Typography>{'das Fahrzeug ist geleast.'}</Typography>
            </div>
        ),
        value: true,
    },
    {
        title: (
            <div className='radio-grid'>
                <b>{'Nein,'}</b>
                <Typography>{'das Fahrzeug ist nicht geleast.'}</Typography>
            </div>
        ),
        value: false,
    },
];

type ReadOnly = {
    [key in keyof VehicleDataState]?: boolean | undefined;
};

type Props = {
    currentIndex: number;
    poweredByOptions: string[];
    leasingCompanyOptions: string[];
};

export const VehicleDataVehicleForm = forwardRef<any, Props>((props, ref) => {
    const { currentIndex, poweredByOptions, leasingCompanyOptions } = props;
    const { state } = useFormState();
    const [isReadonly, setReadonly] = useState<ReadOnly>({});

    const vehicle = state.vehicleData[currentIndex];

    useEffect(() => {
        const readonlyValues: ReadOnly = {
            manufacture: !!vehicle.manufacture && vehicle.manufacture.length > 0,
            power: !!vehicle.power && vehicle.power.length > 0,
            engineType: !!vehicle.engineType && vehicle.engineType.length > 0,
            seats: !!vehicle.seats && vehicle.seats > 0,
        };

        setReadonly(readonlyValues);
    }, [vehicle]);

    const { values, errors, validate, setValue } = useForm({
        initialValues: vehicle || {},
        validators: {
            changeInsurer: (value) =>
                value != null ? undefined : (
                    <Typography>{'Bitte eine Option asuwählen.'}</Typography>
                ),
            engineType: (value) =>
                value != null &&
                value != '' &&
                poweredByOptions.some((item) => item == value) ? undefined : (
                    <Typography>{'Bitte einen gültigen Antrieb angeben.'}</Typography>
                ),
            power: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte die Leistung Ihres Fahrzeugs angeben.'}</Typography>
                ),
            grossListPrice: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte den Listenpreis des Fahrzeugs angeben.'}</Typography>
                ),
            seats: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte die Anzahl an Sitzplätzen angeben.'}</Typography>
                ),
            optionalEquipment: (value) =>
                value && !Number.isNaN(value) ? undefined : (
                    <Typography>{'Bitte den Preis der Sonderausstattung angeben.'}</Typography>
                ),
            licensePlateNumber: (value, otherValues) =>
                !otherValues.changeInsurer ||
                (value != null &&
                    value != '' &&
                    licenseNumberRegex.test(value as string)) ? undefined : (
                    <Typography>
                        {'Bitte ein gültiges Kennzeichen im Format W-32434I ein.'}
                    </Typography>
                ),
            vin: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte dein FIN-Check erneut durchführen.'}</Typography>
                ),
            manufacture: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte den Hersteller angeben.'}</Typography>
                ),
            carName: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte das Modell angeben.'}</Typography>
                ),
            firstRegistration: (value, otherValues) => {
                if (!otherValues.changeInsurer) {
                    return undefined;
                }

                if (!value) {
                    return <Typography>{'Bitte eine Erstzulassung angeben.'}</Typography>;
                }

                const now = dayjs();
                const registrationDate = dayjs(value as any);

                if (registrationDate > now) {
                    return (
                        <Typography>
                            {'Die Erstzulassung darf nicht in der Zukunft liegen.'}
                        </Typography>
                    );
                }

                return undefined;
            },
            leasing: (value) =>
                value != null ? undefined : (
                    <Typography>{'Bitte eine Option auswählen.'}</Typography>
                ),
            leasingCompany: (value, otherValues) =>
                !otherValues.leasing || (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte ein Leasinggeber angeben.'}</Typography>
                ),
        },
    });

    useImperativeHandle(ref, () => ({
        executeValidate() {
            return validate();
        },

        getValues() {
            return values;
        },
    }));

    return (
        <div>
            <Typography className='padded-control text-paragraph'>{`Taxifahrzeug ${
                currentIndex + 1
            }`}</Typography>
            <FormRadioGroup
                options={changeInsurerOptions}
                onChange={(value) => setValue('changeInsurer', value as boolean)}
                value={false} //values.changeInsurer
                error={errors.changeInsurer}
                readonly
            />

            <div className='view-card'>
                <FormTextInput
                    id='vehicleIdentificationNumber'
                    label='FIN (Fahrzeug-Identifikationsnummer)'
                    required
                    readonly
                    value={values.vin}
                    error={errors.vin}
                    tooltip={
                        <div>
                            <div>
                                {
                                    'Die FIN ist die international genormte, 17-stellige Seriennummer mit der ein Kraftfahrzeug eindeutig identifizierbar ist.'
                                }
                            </div>
                            <div>
                                {
                                    'Ihre FIN finden Sie im Zulassungsschein oder Übereinstimmungsbescheinigung (Coc). Außerdem befindet sich die Nummer am Fahrzeug selbst, meist im Motorraum oder an der rechten Fahrzeughälfte.'
                                }
                            </div>
                        </div>
                    }
                />
                <FormTextInput
                    id='manufacturer'
                    label='Hersteller'
                    required
                    readonly={isReadonly.manufacture}
                    onChange={(value) => {
                        setValue('manufacture', value);
                    }}
                    value={values.manufacture}
                    error={errors.manufacture}
                />
                <FormTextInput
                    id='model'
                    label='Modellbezeichnung'
                    required
                    maxLength={30}
                    onChange={(value) => {
                        setValue('carName', value);
                    }}
                    value={values.carName}
                    error={errors.carName}
                />
                <FormTextInput
                    id='power'
                    label='Leistung in kW'
                    required
                    readonly={isReadonly.power}
                    onChange={(value) => {
                        setValue('power', value?.replace(numberRegex, ''));
                    }}
                    value={values.power}
                    error={errors.power}
                />
                <FormSelectInput
                    id='engineType'
                    label='Antriebsart'
                    required
                    readonly={isReadonly.engineType}
                    options={poweredByOptions}
                    onChange={(value) => setValue('engineType', value)}
                    value={values.engineType}
                    error={errors.engineType}
                />
                <FormNumberInput
                    id='seats'
                    label='Anzahl der Sitzplätze'
                    required
                    readonly={isReadonly.seats}
                    onChange={(value) => {
                        setValue('seats', value?.replace(numberRegex, ''));
                    }}
                    value={values.seats != null ? String(values.seats) : ''}
                    error={errors.seats}
                />
                {
                    /*values.changeInsurer*/ false && (
                        <>
                            <FormTextInput
                                id='licensePlateNumber'
                                label='Kennzeichen'
                                required
                                onChange={(value) =>
                                    setValue('licensePlateNumber', value?.toUpperCase())
                                }
                                value={values.licensePlateNumber}
                                error={errors.licensePlateNumber}
                            />
                            <FormDateInput
                                id='firstRegistration'
                                label='Erstzulassung'
                                required
                                onChange={(value) => setValue('firstRegistration', value)}
                                value={values.firstRegistration}
                                error={errors.firstRegistration}
                            />
                        </>
                    )
                }
                <FormNumberInput
                    id='grossListPrice'
                    label='Brutto-Listenpreis'
                    required
                    onChange={(value) => setValue('grossListPrice', value)}
                    value={values.grossListPrice != null ? String(values.grossListPrice) : ''}
                    error={errors.grossListPrice}
                    unit='EUR'
                    allowDecimals
                    withThousandSeperators
                    tooltip={grossListPriceTooltip}
                />
                <FormNumberInput
                    id='optionalEquipment'
                    label='Sonderausstattung'
                    required
                    onChange={(value) => setValue('optionalEquipment', value)}
                    value={values.optionalEquipment != null ? String(values.optionalEquipment) : ''}
                    error={errors.optionalEquipment}
                    unit='EUR'
                    allowDecimals
                    withThousandSeperators
                    tooltip={optionalEquipmentTooltip}
                />
            </div>
            <div className='view-card'>
                <Typography className='padded-control'>{'Ist das Fahrzeug geleast?'}</Typography>
                <FormRadioGroup
                    onChange={(value) => setValue('leasing', value as boolean | undefined)}
                    options={leasingOptions}
                    value={values.leasing}
                    error={errors.leasing}
                />
                {values.leasing && (
                    <>
                        <FormSelectInput
                            id='leasingCompany'
                            label='Leasinggeber (Vinkulargläubiger)'
                            required
                            options={leasingCompanyOptions}
                            onChange={(value) => setValue('leasingCompany', value)}
                            value={values.leasingCompany}
                            error={errors.leasingCompany}
                            tooltip={leasingCompanyTooltip}
                            showSearch
                        />
                        <div className='padded-control infobox'>
                            <ExclamationCircleFilled style={{ color: '#0083a9' }} />
                            <Typography className='infobox'>
                                {
                                    'Für die Vinkulierung wird eine einmalige Gebühr in Höhe von EUR 15,- (inkl. 20% Ust.) pro Fahrzeug eingehoben.'
                                }
                            </Typography>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
