import React, { ReactElement } from 'react';
import Link from 'antd/es/typography/Link';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

export interface NeedQuestionProps {
    question: string;
    infoText?: string;
    renderSelection: () => ReactElement;
    answer?: string;
    changeValue: () => void;
}

export const NeedQuestion = ({
    question,
    answer,
    renderSelection,
    changeValue,
    infoText,
}: NeedQuestionProps): ReactElement => {
    return (
        <div style={{ background: 'white', padding: 12, marginBottom: 24 }}>
            <div>
                <span>{question}</span>
                {infoText && (
                    <Tooltip placement='bottom' title={infoText}>
                        <QuestionCircleFilled style={{ color: '#428dcf', marginLeft: 4 }} />
                    </Tooltip>
                )}
            </div>
            {!answer && <div style={{ fontWeight: 600 }}>{renderSelection()}</div>}
            {answer && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 12,
                        fontWeight: 600,
                    }}
                >
                    <div>{answer}</div>
                    <div>
                        <Link onClick={changeValue}>Ändern {'>>'}</Link>
                    </div>
                </div>
            )}
        </div>
    );
};
