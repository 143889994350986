import { Typography } from 'antd';
import { Page } from 'main-page/pages';
import React from 'react';
import { useFormState } from '../../../lib/state';
import { NavigationContainer } from '../../components';
import '../../styles/common.scss';
import { ViewProps } from '../view-props';
import './credibility-failed-view.scss';

export const CredibilityFailedView: React.FC<ViewProps> = ({ nextPage }: ViewProps) => {
    const { resetState } = useFormState();

    const onClick = (): void => {
        resetState();
        nextPage(Page.LandingView);
    };

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Bonitätsprüfung'}</Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography className='padded-control header centered'>
                    {'Ihr Bonitäts-Check war leider nicht erfolgreich.'}
                </Typography>
                <Typography
                    className='padded-control subheader centered'
                    style={{ marginBottom: '2rem' }}
                >
                    {'Zum aktuellen Zeitpunkt können wir Ihnen kein Angebot erstellen.'}
                </Typography>
                <Typography
                    className='padded-control subheader centered'
                    style={{ marginBottom: '5rem' }}
                >
                    {'Versuchen Sie es gerne zu einem späteren Zeitpunkt nochmals.'}
                </Typography>
                <NavigationContainer forwardText='Zurück zur Startseite' forward={onClick} />
            </div>
        </div>
    );
};
