import { QuestionCircleFilled } from '@ant-design/icons';
import { Radio, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { Page } from 'main-page/pages';
import React, { useEffect, useRef, useState } from 'react';
import { PersonalDataState, useFormState } from '../../../lib/state';
import { ErrorState, isEmail, useForm } from '../../../lib/validation';
import {
    FormDateInput,
    FormSelectInput,
    FormTextInput,
    NavigationContainer,
} from '../../components';
import { ViewProps } from '../view-props';
import './personal-details-view.scss';
import {
    addressAdditionRegexInverted,
    numberRegex,
    phoneNumberRegexInverted,
} from '../../../lib/validation/regex';
import { useApi } from '../../../lib/api';

const tooltipWithEntry =
    'Das Firmenbuch ist ein öffentliches Verzeichnis, in dem alle relevanten Informationen von eingetragenen Unternehmen zu finden sind. Der Firmenbucheintrag entspricht der Registrierung eines Unternehmens im Firmenbuch mitsamt der relevanten Information zum Unternehmen.';
const tooltipWithoutEntry = (
    <>
        <p>
            {'Ihr Unternehmen ist '}
            <u>{'nicht'}</u>
            {' im Firmenbuch eingetragen, verfügt aber über ein Steuer-ID.'}
        </p>
        <p>{tooltipWithEntry}</p>
    </>
);

type FormValues = {
    sizeSelection?: 'PRIVAT' | 'FIRMA' | undefined;
    companyNumber?: string | undefined;
    companyName?: string | undefined;
    street?: string | undefined;
    houseNumber?: string | undefined;
    addressAddition?: string | undefined;
    postalCode?: string | undefined;
    city?: string | undefined;
    email?: string | undefined;
    phonenumber?: string | undefined;
    gender?: string | undefined;
    tradeLicenseNumber?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    dateOfBirth?: dayjs.Dayjs | null | undefined;
};

export const PersonalDetailsView: React.FC<ViewProps> = ({ nextPage }: ViewProps) => {
    const { state, updateState } = useFormState();
    const api = useApi();
    const [genderOptions, setGenderOptions] = useState<string[]>([]);

    const companyNumberRef = useRef<HTMLDivElement>(null);
    const companyNameRef = useRef<HTMLDivElement>(null);
    const streetRef = useRef<HTMLDivElement>(null);
    const houseNumberRef = useRef<HTMLDivElement>(null);
    const postalCodeRef = useRef<HTMLDivElement>(null);
    const cityRef = useRef<HTMLDivElement>(null);
    const emailRef = useRef<HTMLDivElement>(null);
    const firstNameRef = useRef<HTMLDivElement>(null);
    const lastNameRef = useRef<HTMLDivElement>(null);
    const dateOfBirthRef = useRef<HTMLDivElement>(null);
    const phonenumberRef = useRef<HTMLDivElement>(null);
    const genderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const requestOptions = async (): Promise<void> => {
            try {
                const data = await api.getPoweredByPrefillings();
                setGenderOptions(data?.genderList ?? []);
            } catch {
                setGenderOptions([]);
            }
        };

        void requestOptions();
    }, []);

    const onFailure = (invalid: ErrorState<FormValues>): void => {
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (values.sizeSelection === 'FIRMA') {
            if (invalid.companyNumber) {
                scrollIntoView = companyNumberRef;
            } else if (invalid.companyName) {
                scrollIntoView = companyNameRef;
            } else if (invalid.street) {
                scrollIntoView = streetRef;
            } else if (invalid.houseNumber) {
                scrollIntoView = houseNumberRef;
            } else if (invalid.postalCode) {
                scrollIntoView = postalCodeRef;
            } else if (invalid.city) {
                scrollIntoView = cityRef;
            } else if (invalid.email) {
                scrollIntoView = emailRef;
            } else if (invalid.phonenumber) {
                scrollIntoView = phonenumberRef;
            }
        } else {
            if (invalid.gender) {
                scrollIntoView = genderRef;
            } else if (invalid.firstName) {
                scrollIntoView = firstNameRef;
            } else if (invalid.lastName) {
                scrollIntoView = lastNameRef;
            } else if (invalid.dateOfBirth) {
                scrollIntoView = dateOfBirthRef;
            } else if (invalid.street) {
                scrollIntoView = streetRef;
            } else if (invalid.houseNumber) {
                scrollIntoView = houseNumberRef;
            } else if (invalid.postalCode) {
                scrollIntoView = postalCodeRef;
            } else if (invalid.city) {
                scrollIntoView = cityRef;
            } else if (invalid.email) {
                scrollIntoView = emailRef;
            } else if (invalid.phonenumber) {
                scrollIntoView = phonenumberRef;
            }
        }

        if (scrollIntoView && scrollIntoView.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const onSuccess = (): void => {
        const newPersonalData: PersonalDataState = {
            status: values.sizeSelection as 'PRIVAT' | 'FIRMA',
            companyNumber: values.companyNumber as string,
            companyName: values.companyName as string,
            street: values.street as string,
            houseNumber: values.houseNumber as string,
            addressAddition: values.addressAddition,
            postalCode: values.postalCode as string,
            city: values.city as string,
            email: values.email as string,
            phonenumber: values.phonenumber as string,
            firstName: values.firstName as string,
            lastName: values.lastName as string,
            dateOfBirth: values.dateOfBirth as dayjs.Dayjs,
            validated: 'yes',
            gender: values.sizeSelection === 'PRIVAT' ? values.gender : undefined,
        };

        const hasChanges = JSON.stringify(state.personalData) !== JSON.stringify(newPersonalData);

        updateState({
            ...state,
            personalData: newPersonalData,
        });

        if (state.paymentDetails.credibilityChecked && !hasChanges) {
            nextPage(Page.VehicleDataView);
        } else {
            nextPage(Page.CredibilityCheckView);
        }
    };

    const { values, errors, setValue, submit } = useForm({
        initialValues: { ...state.personalData } as FormValues,
        onSuccess: onSuccess,
        onFailure: onFailure,
        validators: {
            companyNumber: (value, otherValues) =>
                otherValues.sizeSelection != 'FIRMA' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Firmenbuchnummer an.'}</Typography>
                ),
            companyName: (value, otherValues) =>
                otherValues.sizeSelection != 'FIRMA' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihren Firmennamen an.'}</Typography>
                ),
            street: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Straße an.'}</Typography>
                ),
            houseNumber: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Hausnummer an.'}</Typography>
                ),
            postalCode: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Postleitzahl an.'}</Typography>
                ),
            city: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Stadt an.'}</Typography>
                ),
            email: (value) =>
                isEmail(value as string | undefined) ? undefined : (
                    <Typography>{'Bitte geben Sie eine gültige E-Mail Adresse an.'}</Typography>
                ),
            firstName: (value, otherValues) =>
                otherValues.sizeSelection != 'PRIVAT' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihren Vornamen an.'}</Typography>
                ),
            lastName: (value, otherValues) =>
                otherValues.sizeSelection != 'PRIVAT' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihren Nachnamen an.'}</Typography>
                ),
            dateOfBirth: (value, otherValues) =>
                otherValues.sizeSelection != 'PRIVAT' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihr Geburtsdatum an.'}</Typography>
                ),
            phonenumber: (value) =>
                value != null && value != '' ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Telefonnummer an.'}</Typography>
                ),
            gender: (value, otherValues) =>
                otherValues.sizeSelection != 'PRIVAT' ||
                (value != null && value != '') ? undefined : (
                    <Typography>{'Bitte geben Sie Ihre Geschlechtsangabe an.'}</Typography>
                ),
        },
    });

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    {'In wenigen Schritten zu Ihrer Uber Autoversicherung.'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                <Typography className='padded-control subheader'>{'Ihre Firma'}</Typography>
                <div>
                    <div className='padded-control details-page-radio'>
                        <Radio
                            id='size-selection-company'
                            checked={values.sizeSelection === 'FIRMA'}
                            onChange={() => setValue('sizeSelection', 'FIRMA')}
                        />
                        <Typography>
                            {'Unternehmen '}
                            <b>{'mit'}</b>
                            {' Firmenbucheintrag'}
                        </Typography>
                        <Tooltip title={tooltipWithEntry} color='#0083a9'>
                            <QuestionCircleFilled
                                style={{ color: '#0083a9', marginLeft: '1rem' }}
                            />
                        </Tooltip>
                    </div>
                    {values.sizeSelection === 'FIRMA' && (
                        <>
                            <FormTextInput
                                id='company-number'
                                label='Firmenbuchnummer'
                                ref={companyNumberRef}
                                required
                                onChange={(value) => setValue('companyNumber', value)}
                                value={values.companyNumber}
                                error={errors.companyNumber}
                            />
                            <FormTextInput
                                id='company-name'
                                label='Firmenname'
                                ref={companyNameRef}
                                required
                                onChange={(value) => setValue('companyName', value)}
                                value={values.companyName}
                                error={errors.companyName}
                            />
                            <FormTextInput
                                id='street'
                                label='Straße'
                                ref={streetRef}
                                required
                                onChange={(value) => setValue('street', value)}
                                value={values.street}
                                error={errors.street}
                            />
                            <FormTextInput
                                id='houseNumber'
                                label='Hausnummer'
                                ref={houseNumberRef}
                                required
                                maxLength={4}
                                onChange={(value) => {
                                    setValue('houseNumber', value?.replace(/\D/g, ''));
                                }}
                                value={values.houseNumber}
                                error={errors.houseNumber}
                            />
                            <FormTextInput
                                id='addressAddition'
                                label='Adresszusatz'
                                onChange={(value) => {
                                    setValue(
                                        'addressAddition',
                                        value?.replace(addressAdditionRegexInverted, '')
                                    );
                                }}
                                value={values.addressAddition}
                                error={errors.addressAddition}
                            />
                            <FormTextInput
                                id='postal-code'
                                label='Postleitzahl'
                                ref={postalCodeRef}
                                required
                                maxLength={4}
                                onChange={(value) => {
                                    setValue('postalCode', value?.replace(numberRegex, ''));
                                }}
                                value={values.postalCode}
                                error={errors.postalCode}
                            />
                            <FormTextInput
                                id='city'
                                label='Stadt'
                                ref={cityRef}
                                required
                                onChange={(value) => setValue('city', value)}
                                value={values.city}
                                error={errors.city}
                            />
                            <FormTextInput
                                id='email'
                                label='E-Mail'
                                ref={emailRef}
                                required
                                onChange={(value) => setValue('email', value)}
                                value={values.email}
                                error={errors.email}
                            />
                            <FormTextInput
                                id='phone-number'
                                label='Telefonnummer'
                                ref={phonenumberRef}
                                required
                                onChange={(value) => {
                                    setValue(
                                        'phonenumber',
                                        value?.replace(phoneNumberRegexInverted, '')
                                    );
                                }}
                                value={values.phonenumber}
                                error={errors.phonenumber}
                            />
                        </>
                    )}
                    <div className='padded-control details-page-radio'>
                        <Radio
                            id='size-selection-small-business'
                            checked={values.sizeSelection === 'PRIVAT'}
                            onChange={() => setValue('sizeSelection', 'PRIVAT')}
                        />
                        <Typography>
                            {'Unternehmen '}
                            <b>{'ohne'}</b>
                            {' Firmenbucheintrag'}
                        </Typography>
                        <Tooltip title={tooltipWithoutEntry} color='#0083a9'>
                            <QuestionCircleFilled
                                style={{ color: '#0083a9', marginLeft: '1rem' }}
                            />
                        </Tooltip>
                    </div>
                    {values.sizeSelection === 'PRIVAT' && (
                        <>
                            <FormSelectInput
                                id='gender'
                                label='Geschlecht'
                                required
                                ref={genderRef}
                                options={genderOptions}
                                onChange={(value) => setValue('gender', value)}
                                value={values.gender}
                                error={errors.gender}
                                showSearch
                            />
                            <FormTextInput
                                id='firstname'
                                label='Vorname'
                                ref={firstNameRef}
                                required
                                onChange={(value) => setValue('firstName', value)}
                                value={values.firstName}
                                error={errors.firstName}
                            />
                            <FormTextInput
                                id='last-name'
                                label='Nachname'
                                ref={lastNameRef}
                                required
                                onChange={(value) => setValue('lastName', value)}
                                value={values.lastName}
                                error={errors.lastName}
                            />
                            <FormDateInput
                                id='date-of-birth'
                                label='Geburtsdatum'
                                ref={dateOfBirthRef}
                                required
                                onChange={(value) => setValue('dateOfBirth', value)}
                                value={values.dateOfBirth}
                                error={errors.dateOfBirth}
                            />
                            <FormTextInput
                                id='street'
                                label='Straße'
                                ref={streetRef}
                                required
                                onChange={(value) => setValue('street', value)}
                                value={values.street}
                                error={errors.street}
                            />
                            <FormTextInput
                                id='houseNumber'
                                label='Hausnummer'
                                ref={houseNumberRef}
                                required
                                maxLength={4}
                                onChange={(value) => {
                                    setValue('houseNumber', value?.replace(numberRegex, ''));
                                }}
                                value={values.houseNumber}
                                error={errors.houseNumber}
                            />
                            <FormTextInput
                                id='addressAddition'
                                label='Adresszusatz'
                                onChange={(value) => {
                                    setValue(
                                        'addressAddition',
                                        value?.replace(addressAdditionRegexInverted, '')
                                    );
                                }}
                                value={values.addressAddition}
                                error={errors.addressAddition}
                            />
                            <FormTextInput
                                id='postal-code'
                                label='Postleitzahl'
                                ref={postalCodeRef}
                                required
                                maxLength={4}
                                onChange={(value) => {
                                    setValue('postalCode', value?.replace(numberRegex, ''));
                                }}
                                value={values.postalCode}
                                error={errors.postalCode}
                            />
                            <FormTextInput
                                id='city'
                                label='Stadt'
                                ref={cityRef}
                                required
                                onChange={(value) => setValue('city', value)}
                                value={values.city}
                                error={errors.city}
                            />
                            <FormTextInput
                                id='email'
                                label='E-Mail'
                                ref={emailRef}
                                required
                                onChange={(value) => setValue('email', value)}
                                value={values.email}
                                error={errors.email}
                            />
                            <FormTextInput
                                id='phone-number'
                                label='Telefonnummer'
                                ref={phonenumberRef}
                                required
                                onChange={(value) => {
                                    setValue(
                                        'phonenumber',
                                        value?.replace(phoneNumberRegexInverted, '')
                                    );
                                }}
                                value={values.phonenumber}
                                error={errors.phonenumber}
                            />
                        </>
                    )}
                </div>
            </div>
            <NavigationContainer forwardText='Prämie berechnen' forward={submit} />
            <div className='view-card-bottom' style={{ background: 'transparent' }} />
        </div>
    );
};
