import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Page } from 'main-page/pages';
import React from 'react';
import { FailedSubmitState } from '../../../../../lib/state';
import { NavigationContainer } from '../../../../components';
import { ViewProps } from '../../../view-props';

type Props = ViewProps & {
    details: FailedSubmitState;
    index: number;
    submitAgain: () => boolean | Promise<boolean>;
};

export const VehicleSubmitResult: React.FC<Props> = ({
    details,
    index,
    nextPage,
    setSubmitParameters,
    submitAgain,
}: Props) => {
    const submit = async (): Promise<void> => {
        if (!setSubmitParameters) {
            nextPage(Page.ErrorViewDefault);
            return;
        }

        setSubmitParameters({
            longRunningAction: submitAgain,
            successRedirect: Page.SubmitSuccessView,
            failureRedirect: Page.SubmitFailureView,
        });
        nextPage(Page.SubmitLoadingView);
    };

    let classes = '';
    if (details.status === 'completed') {
        classes = 'completed';
    }

    return (
        <div className='padded-control result-box' key={`vehicle-submit-result-${index}`}>
            <div>
                <div>
                    <Typography className={classes} style={{ marginBottom: '0.5rem' }}>
                        <b>{`Fahrzeug ${index + 1}`}</b>
                    </Typography>
                    <Typography className={classes}>{`FIN ${details.vin}`}</Typography>
                    <Typography className={classes}>{details.model}</Typography>
                </div>
                <div style={{ margin: 'auto 0' }}>
                    {details.status === 'completed' ? (
                        <span>
                            <span className='check circle completed'>
                                <CheckOutlined className='check completed' />
                            </span>
                            <Typography className='check completed'>
                                {'Antrag übermittelt'}
                            </Typography>
                        </span>
                    ) : (
                        <span>
                            <span className='cross circle'>
                                <CloseOutlined className='cross' />
                            </span>
                            <Typography className='cross'>{'Übermittlung abgebrochen'}</Typography>
                        </span>
                    )}
                </div>
                {details.status !== 'completed' && (
                    <div className='button'>
                        <NavigationContainer
                            forwardText='Übermittlung wiederholen'
                            forward={submit}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
