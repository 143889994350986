import { ViewProps } from '../view-props';
import { DocumentLink, Documents, NavigationContainer } from '../../components';
import React from 'react';
import { Page } from '../../pages';
import { useFormState } from '../../../lib/state';
import { NeedQuestion } from './need-question';
import { useForm } from '../../../lib/validation';
import { FormRadioGroup } from '../../components/form-fields/form-radio-group';
import { YesNoNeedQuestion } from './yes-no-need-question';
import { Divider, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import './needs-analysis-view.scss';

export const NeedsAnalysisView = ({ nextPage }: ViewProps): React.ReactElement => {
    const { state, updateState } = useFormState();

    const forward = (): void => {
        updateState({ ...state, needAnalysis: values });
        nextPage(Page.CarCountChoiceView, false);
    };

    const { values, errors, setValue, resetValues } = useForm({
        initialValues: state.needAnalysis,
        validators: {
            isVehicleApprovedInAustria: (value) => !(value != null && value),
            isBetterProtectionThanLegalProtectionWanted: (value) => !(value != null && value),
            isVehicleDamageProtectionWanted: (value) => !(value != null && value),
            wantedInsuranceType: (value) => value !== 'Vollkasko',
            protectedPeople: (value) => !(value != null && value != 'Insassen'),
        },
    });

    const hasError =
        errors.isVehicleApprovedInAustria ||
        errors.isBetterProtectionThanLegalProtectionWanted ||
        errors.isVehicleDamageProtectionWanted ||
        errors.wantedInsuranceType ||
        errors.protectedPeople;

    const canRender2 = values.isVehicleApprovedInAustria && !errors.isVehicleApprovedInAustria;
    const canRender3 =
        canRender2 &&
        values.isBetterProtectionThanLegalProtectionWanted &&
        !errors.isBetterProtectionThanLegalProtectionWanted;
    const canRender4 =
        canRender3 &&
        values.isVehicleDamageProtectionWanted &&
        !errors.isVehicleDamageProtectionWanted;
    const canRender5 = canRender4 && values.wantedInsuranceType && !errors.wantedInsuranceType;

    const isSuccess = canRender5 && values.protectedPeople && !errors.protectedPeople;

    return (
        <div className='page' style={{ maxWidth: '400px' }}>
            <div className='view-card-top'>
                <Typography className='padded-control header'>
                    Die passende Versicherung für Ihr Auto.
                </Typography>
            </div>
            <div className='padded-control header' style={{ marginBottom: 8 }}>
                Wünsche und Bedürfnisse
            </div>
            <Divider className='row-divider' />
            <div
                className='padded-control'
                style={{ color: 'gray', fontWeight: 500, marginBottom: 48, marginTop: 18 }}
            >
                Finden Sie mit ein paar Klicks das für Sie und Ihr Taxifahrzeug richtige Produkt.
                Beantworten Sie uns dazu ein paar wenige Fragen in der Bedarfsanalyse. Im Anschluss
                haben wir eine Empfehlung für Sie.
            </div>
            <div>
                <YesNoNeedQuestion
                    question='1. Ist bzw. wird das Fahrzeug in Österreich zugelassen?'
                    onChange={(value) => setValue('isVehicleApprovedInAustria', value as boolean)}
                    value={values.isVehicleApprovedInAustria}
                    changeValue={() => setValue('isVehicleApprovedInAustria', undefined)}
                />
                {canRender2 && (
                    <YesNoNeedQuestion
                        question='2. Wünschen Sie eine höhere Absicherung in der Haftpflicht-Versicherung als gesetzlich vorgeschrieben?'
                        onChange={(value) =>
                            setValue(
                                'isBetterProtectionThanLegalProtectionWanted',
                                value as boolean
                            )
                        }
                        value={values.isBetterProtectionThanLegalProtectionWanted}
                        changeValue={() =>
                            setValue('isBetterProtectionThanLegalProtectionWanted', undefined)
                        }
                    />
                )}
                {canRender3 && (
                    <YesNoNeedQuestion
                        question='3. Wünschen Sie eine Absicherung durch eine Kaskoversicherung gegen Schäden am eigenen Kraftfahrzeug?'
                        onChange={(value) =>
                            setValue('isVehicleDamageProtectionWanted', value as boolean)
                        }
                        value={values.isVehicleDamageProtectionWanted}
                        changeValue={() => setValue('isVehicleDamageProtectionWanted', undefined)}
                        infoText='Kaskoversicherung z.B. gegen Unfall, Vandalismus, Parkschaden, Diebstahl, Glasbruch...'
                    />
                )}
                {canRender4 && (
                    <NeedQuestion
                        question='4. Welche Kfz-Kaskoversicherung wünschen Sie?'
                        answer={
                            values.wantedInsuranceType == null
                                ? undefined
                                : values.wantedInsuranceType
                        }
                        renderSelection={() => (
                            <FormRadioGroup
                                options={[
                                    {
                                        title: 'Vollkasko',
                                        value: 'Vollkasko',
                                        infoText:
                                            'Gedeckt sind Schäden an Ihrem Fahrzeug aufgrund von: Brand, Tierschäden, Naturgewalten, Diebstahl, Glasbruch, Parkschaden, Vandalsimus, Unfall',
                                    },
                                    {
                                        title: 'Parkschadenkasko',
                                        value: 'Parkschadenkasko',
                                        infoText:
                                            'Gedeckt sind Schäden an Ihrem Fahrzeug aufgrund von: Brand, Tierschäden, Naturgewalten, Diebstahl, Glasbruch, Parkschaden, Vandalsimus',
                                    },
                                    {
                                        title: 'Teilkasko',
                                        value: 'Teilkasko',
                                        infoText:
                                            'Gedeckt sind Schäden an Ihrem Fahrzeug aufgrund von: Brand, Tierschäden, Naturgewalten, Diebstahl, Glasbruch',
                                    },
                                    {
                                        title: 'Eingeschränkte Teilkasko',
                                        value: 'Eingeschränkte Teilkasko',
                                        infoText:
                                            'Gedeckt sind Schäden an Ihrem Fahrzeug aufgrund von: Brand, Tierschäden, Naturgewalten',
                                    },
                                ]}
                                onChange={(value) =>
                                    setValue('wantedInsuranceType', value as string)
                                }
                                value={values.wantedInsuranceType}
                            />
                        )}
                        changeValue={() => setValue('wantedInsuranceType', undefined)}
                    />
                )}
                {canRender5 && (
                    <NeedQuestion
                        question='5. Möchten Sie sich oder die Insassen bei persönlichen Unfallfolgen absichern?'
                        answer={values.protectedPeople == null ? undefined : values.protectedPeople}
                        renderSelection={() => (
                            <FormRadioGroup
                                options={[
                                    {
                                        title: 'Mich',
                                        value: 'Mich',
                                    },
                                    {
                                        title: 'Insassen',
                                        value: 'Insassen',
                                    },
                                    {
                                        title: 'Nein',
                                        value: 'Nein',
                                    },
                                ]}
                                onChange={(value) => setValue('protectedPeople', value as string)}
                                value={values.protectedPeople}
                            />
                        )}
                        changeValue={() => setValue('protectedPeople', undefined)}
                        infoText='Finanzielle Vorsorge für den Fall, dass Sie oder Ihre Insassen bei einem Unfall getötet oder verletzt werden.'
                    />
                )}
            </div>
            {isSuccess && (
                <div className='padded-control'>
                    <div style={{ marginBottom: 12 }}>
                        Wünsche- und Bedürfnisanalyse vom{' '}
                        {state.needAnalysis.date.format('DD.MM.YYYY HH:mm')}
                    </div>
                    <Divider />

                    <div style={{ marginBottom: 12, fontWeight: 600 }}>Unsere Empfehlung</div>
                    <div>
                        Wir empfehlen Ihnen diesen Vertrag, weil er mit den unten angeführten
                        Leistungen am besten Ihren Wünschen und Bedürfnissen entspricht:
                    </div>
                    <div
                        style={{
                            marginTop: 24,
                            marginBottom: 24,
                            padding: 12,
                            border: '1px solid #4CA8C2',
                            borderRadius: '12px',
                            background: '#E6F2F6',
                        }}
                    >
                        <div style={{ fontWeight: 600 }}>
                            Kfz-Versicherung gemäß den allgemeinen und besonderen
                            Versicherungsbedingungen
                        </div>
                        <div style={{ margin: 12, marginLeft: 16 }}>
                            <div className='needs-card-section'>
                                <div className='needs-card-title'>
                                    <CheckOutlined className='needs-card-title-icon' />{' '}
                                    Kfz-Haftpflichtversicherung
                                </div>
                                <div className='needs-card-subtitle'>
                                    <div>Pauschalversicherungssumme</div>
                                    <div>30.000.000 Mio €</div>
                                    <div>400 € Schadenersatzbeitrag</div>
                                </div>
                            </div>
                            <div className='needs-card-section'>
                                <div className='needs-card-title'>
                                    <CheckOutlined className='needs-card-title-icon' />{' '}
                                    Kfz-Vollkaskoversicherung
                                </div>
                                <div className='needs-card-subtitle'>
                                    <div>Mit generellem Selbstbehalt von</div>
                                    <div>1.000 €</div>
                                </div>
                            </div>
                            {values.protectedPeople !== 'Nein' && (
                                <div className='needs-card-section'>
                                    <div className='needs-card-title'>
                                        <CheckOutlined className='needs-card-title-icon' />{' '}
                                        Kfz-Lenkerunfallversicherung
                                    </div>
                                    <div className='needs-card-subtitle'>
                                        <div>40.000 € Invalidät, 20.000 € Todesfall</div>
                                    </div>
                                </div>
                            )}

                            <div className='needs-card-section' style={{ marginTop: 24 }}>
                                <DocumentLink
                                    title='Produktinformationsblatt Kfz-Haftpflichtversicherung'
                                    filePath={Documents.Haftpflicht}
                                />
                            </div>
                            <div className='needs-card-section'>
                                <DocumentLink
                                    title='Produktinformationsblatt Vollkaskoversicherung'
                                    filePath={Documents.Kasko}
                                />
                            </div>
                            {values.protectedPeople !== 'Nein' && (
                                <div className='needs-card-section'>
                                    <DocumentLink
                                        title='Produktinformationsblatt Kfz-Unfallversicherung'
                                        filePath={Documents.Lenkerschutz}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <NavigationContainer forwardText='Prämie berechnen' forward={forward} />
                </div>
            )}
            {hasError && (
                <div className='padded-control'>
                    <div>
                        Wünsche- und Bedürfnisanalyse vom{' '}
                        {state.needAnalysis.date.format('DD.MM.YYYY HH:mm')}
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 16, fontWeight: 600 }}>
                        Basierend auf Ihren Angaben haben wir leider keine Produktempfehlung für
                        Sie.
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        Beginnen Sie entweder eine neue Wünsche- und Bedürfnisanalyse oder nehmen
                        sie Kontakt mit uns auf. Unsere Berater*innen stehen Ihnen gerne persönlich
                        zur Verfügung, um Sie zu unterstützen.
                    </div>
                    <NavigationContainer
                        forwardText='Wünsche- und Bedürfnisanalyse neu starten'
                        forward={() => resetValues()}
                    />
                    <Divider />
                    <div style={{ marginBottom: 16, fontWeight: 600 }}>So erreichen Sie uns:</div>
                    <div>
                        Wir sind für Sie werktags zwischen 9–17 Uhr unter der Rufnummer +43 57800
                        450 erreichbar. Oder schreiben Sie uns eine E-Mail an rider@aon-austria.at
                    </div>
                </div>
            )}
            <div className='view-card-bottom' style={{ background: 'transparent' }} />
        </div>
    );
};
