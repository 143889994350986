import { Divider } from 'antd';
import React, { ReactNode } from 'react';

type Props = {
    header: ReactNode;
    value: ReactNode;
    noDivider?: boolean;
};

export const DataRow: React.FC<Props> = ({ header, value, noDivider }: Props) => {
    return (
        <>
            {!noDivider && <Divider className='row-divider' />}
            <div style={{ display: 'flex' }}>
                {header}
                <span style={{ flexGrow: 1 }} />
                {value}
            </div>
        </>
    );
};
