import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { AppHeader } from './app-header';
import './main-page.scss';
import { Page } from './pages';
import { AdditionalLicenseNumberView, ErrorView, LicenseNumberResultView } from './views';

export const LicenseNumberPage: React.FC = () => {
    const [currentPage, setCurrentPage] = useState<Page | undefined>(undefined);
    const [resultState, setResultState] = useState<'exists' | 'completed' | 'Not Found'>(
        'Not Found'
    );

    const nextPage = (page: Page): void => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (currentPage == null) {
            setCurrentPage(Page.AdditionalLicenseNumberView);
        }
    }, []);

    const pageToDisplay = useMemo<ReactNode>(() => {
        switch (currentPage) {
            case Page.ErrorViewDefault:
                return (
                    <ErrorView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        landingPage={Page.AdditionalLicenseNumberView}
                    />
                );

            case Page.AdditionalLicenseNumberView:
                return (
                    <AdditionalLicenseNumberView
                        currentPage={currentPage as Page}
                        nextPage={nextPage}
                        setResultState={setResultState}
                    />
                );

            case Page.LicenseNumberResultView:
                return <LicenseNumberResultView resultState={resultState} />;
        }
    }, [currentPage]);

    return (
        <div>
            <AppHeader
                currentPage={currentPage as Page}
                nextPage={nextPage}
                landingPage={Page.AdditionalLicenseNumberView}
            />
            {pageToDisplay}
        </div>
    );
};
