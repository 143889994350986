import { CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Alert, Divider, Tag, Typography } from 'antd';
import { formatAsCurrency } from 'lib/shared';
import { ErrorState, useForm } from 'lib/validation';
import { Page } from 'main-page/pages';
import React, { useRef, useState } from 'react';
import { InsuranceConclusionRequestDto, useApi } from '../../../lib/api';
import { useFormState } from '../../../lib/state';
import { DocumentLink, Documents, FormCheckboxInput, NavigationContainer } from '../../components';
import { DataRow } from '../../components/data-row';
import { ViewProps } from '../view-props';
import './summary-view.scss';
import { NeedsAnalysisAnswer } from './needs-analysis-answer';
import Link from 'antd/es/typography/Link';

type FormValues = {
    insuranceAccepted?: boolean | undefined;
    contractAccepted?: boolean | undefined;
    electronicAccepted?: boolean | undefined;
    downloadAccepted?: boolean | undefined;
};

export const SummaryView: React.FC<ViewProps> = ({
    nextPage,
    previousPage,
    setSubmitParameters,
}: ViewProps) => {
    const api = useApi();
    const { state, updateState } = useFormState();

    const insuranceAcceptedRef = useRef<HTMLDivElement>(null);
    const contractAcceptedRef = useRef<HTMLDivElement>(null);
    const downloadAcceptedRef = useRef<HTMLDivElement>(null);
    const electronicAcceptedRef = useRef<HTMLDivElement>(null);

    const [isBrokerContractDownloaded, setIsBrokerContractDownloaded] = useState(false);
    const [isAgbDownloaded, setIsAgbDownloaded] = useState(false);
    const [isHaftpflichtDownloaded, setIsHaftpflichtDownloaded] = useState(false);
    const [isKaskoDownloaded, setIsKaskoDownloaded] = useState(false);
    const [isLenkerschutzDownloaded, setIsLenkerschutzDownloaded] = useState(false);
    const [isAonAgbDownloaded, setIsAonAgbDownloaded] = useState(false);
    const [isSubmitDirty, setIsSubmitDirty] = useState(false);

    const allDocumentsDownloaded =
        isBrokerContractDownloaded &&
        isAgbDownloaded &&
        isHaftpflichtDownloaded &&
        isKaskoDownloaded &&
        (isLenkerschutzDownloaded || !state.paymentDetails.passengerAccident) &&
        isAonAgbDownloaded;

    const avbLinkOptions: string[] = ['hp.1', 'ka.1', 'kaiu.1'];
    if (state.vehicleData.some((v) => v.leasing)) {
        avbLinkOptions.push('kapg.1');
    }
    if (state.vehicleData.some((v) => v.engineType?.includes('Elektro'))) {
        avbLinkOptions.push('hy.1');
    }
    if (state.needAnalysis.protectedPeople == 'Insassen') {
        avbLinkOptions.push('iu.1-iulv.1');
    }
    const avbSuffix = avbLinkOptions.length > 0 ? `\\${avbLinkOptions.join('-')}` : '';
    const avbLink = `${Documents.AVB_Base}${avbSuffix}.htm`;

    const onFailure = (invalid: ErrorState<FormValues>): void => {
        let scrollIntoView: React.RefObject<HTMLDivElement> | undefined = undefined;

        if (invalid.downloadAccepted) {
            scrollIntoView = downloadAcceptedRef;
        } else if (invalid.insuranceAccepted) {
            scrollIntoView = insuranceAcceptedRef;
        } else if (invalid.contractAccepted) {
            scrollIntoView = contractAcceptedRef;
        } else if (invalid.electronicAccepted) {
            scrollIntoView = electronicAcceptedRef;
        }

        if (scrollIntoView && scrollIntoView.current) {
            scrollIntoView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    const submitting = async (): Promise<boolean> => {
        if (state.procedureId == null || state.paymentDetails.validated != 'yes') {
            return false;
        }

        const data: InsuranceConclusionRequestDto = {
            procedureId: state.procedureId,
            uuid: state.paymentDetails.uuid,
            bank: { iban: state.paymentDetails.iban, bank: state.paymentDetails.bank },
            confirmation: [
                {
                    name: 'SEPA-MANDAT',
                    status: 'CONFIRMED',
                },
                {
                    name: 'MARKLERVERTRAG',
                    status: 'CONFIRMED',
                },
                {
                    name: 'AKB',
                    status: 'CONFIRMED',
                },
                {
                    name: 'DIGITAL-PROZESS',
                    status: 'CONFIRMED',
                },
            ],
            confirmedCarRateIds: [
                ...state.vehicleData
                    .map((item) => item.rate)
                    .filter((item) => item != null)
                    .map((item) => item?.rateId as string),
            ],
        };
        const response = await api.postInsuranceConclusion(data);
        if (response != null && response.conclusionResponce != null) {
            updateState({ ...state, submitData: response.conclusionResponce });
        }

        return (
            response != null &&
            response.conclusionResponce != null &&
            response.conclusionResponce.length > 0 &&
            response?.conclusionResponce?.every((item) => item.status == 'completed')
        );
    };

    const forward = (): void => {
        if (!setSubmitParameters) {
            nextPage(Page.ErrorViewDefault);
            return;
        }

        setSubmitParameters({
            longRunningAction: submitting,
            successRedirect: Page.SubmitSuccessView,
            failureRedirect: Page.SubmitFailureView,
        });
        nextPage(Page.SubmitLoadingView);
    };

    const { values, errors, setValue, submit } = useForm({
        initialValues: {} as FormValues,
        onSuccess: forward,
        onFailure: onFailure,
        validators: {
            insuranceAccepted: (value) =>
                value != null && value ? undefined : (
                    <Typography>{'Bitte akzeptieren.'}</Typography>
                ),
            contractAccepted: (value) =>
                value != null && value ? undefined : (
                    <Typography>{'Bitte akzeptieren.'}</Typography>
                ),
            downloadAccepted: (value) =>
                value != null && value ? undefined : (
                    <Typography>{'Bitte akzeptieren.'}</Typography>
                ),
            electronicAccepted: (value) =>
                value != null && value ? undefined : (
                    <Typography>{'Bitte akzeptieren.'}</Typography>
                ),
        },
    });

    const renderDownloadHint = (isDownloaded: boolean): React.ReactElement => (
        <Tag color={isDownloaded ? 'green' : 'red'}>
            {!isDownloaded ? 'PDF herunterladen' : 'PDF wurde heruntergeladen'}
        </Tag>
    );

    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>Ihren Bedarf erheben</Typography>
            </div>

            <div className='analysis-card padded-control'>
                <div className='checkmark'>
                    <CheckOutlined style={{ color: 'green' }} />
                </div>
                <div className='block'>
                    Sie haben die <span className='bold'>Online-Wünsche- und Bedürfnisanalyse</span>{' '}
                    am {state.needAnalysis.date.format('DD.MM.YYYY HH:mm')}{' '}
                    <span style={{ fontWeight: 'bold' }}>durchgeführt.</span>
                </div>

                <div className='block'>
                    Dieses <span className='bold'>Produkt wurde</span> aufgrund ihrer Angaben{' '}
                    <span className='bold'>mit folgender Konfiguration vorgeschlagen</span>, um
                    ihren Bedarf abzudecken:
                </div>

                <div>
                    <ul>
                        <li>Kfz-Haftpflichtversicherung</li>
                        <li>Kfz-Vollkaskoversicherung</li>
                        <li>Kfz-Lenkerunfallverischerung</li>
                    </ul>
                </div>
            </div>

            <Typography className='padded-control header' style={{ marginTop: 24 }}>
                <b>{'Ihre Antworten'}</b>
            </Typography>

            <div className='padded-control'>
                <NeedsAnalysisAnswer
                    question='1. Ist bzw. wird das Taxifahrzeug in Österreich zugelassen?'
                    answer={state.needAnalysis.isVehicleApprovedInAustria ? 'Ja' : 'Nein'}
                />
                <NeedsAnalysisAnswer
                    question='2. Wünschen Sie eine höhere Absicherung in der Haftpflicht Versicherung als gesetzlich vorgeschrieben?'
                    answer={
                        state.needAnalysis.isBetterProtectionThanLegalProtectionWanted
                            ? 'Ja'
                            : 'Nein'
                    }
                />
                <NeedsAnalysisAnswer
                    question='3. Wünschen Sie eine Absicherung durch eine Kaskoversicherung gegen Schäden am eigenen Kraftfahrzeug?'
                    answer={state.needAnalysis.isVehicleDamageProtectionWanted ? 'Ja' : 'Nein'}
                />
                <NeedsAnalysisAnswer
                    question='4. Welche Kfz-Kaskoversicherung wünschen Sie?'
                    answer={state.needAnalysis.wantedInsuranceType ?? ''}
                />
                <NeedsAnalysisAnswer
                    question='5. Möchten Sie sich oder die Insassen bei persönlicher Unfallfolge absichern?'
                    answer={state.needAnalysis.protectedPeople ?? ''}
                />
            </div>

            <div className='view-card-bottom'>
                <Typography className='padded-control header'>{'Versicherung'}</Typography>
                <Divider className='row-divider' />
                <Typography className='padded-control header'>
                    <b>{'Leistungsüberblick'}</b>
                </Typography>
                <Typography className='padded-control' style={{ fontSize: '0.95rem' }}>
                    <b>{'Kfz-Haftpflicht- und Vollkasko'}</b>
                </Typography>
                <Divider className='row-divider' />
                <div
                    className='padded-control'
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateRows: '1fr 1fr',
                    }}
                >
                    <Typography>{'Schadenersatzbeitrag'}</Typography>

                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography>
                            <b>{'Haftpflicht 400 € SB'}</b>
                        </Typography>
                    </div>
                    <Typography>{'Selbstbeteiligung (SB)'}</Typography>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }} />
                        <Typography>
                            <b>{'Vollkasko 1.000 € SB'}</b>
                        </Typography>
                    </div>
                </div>
                <Divider className='summary-divider' />
                <Typography
                    className='padded-control'
                    style={{ fontSize: '0.95rem', marginBottom: 62 }}
                >
                    <b>{'Kfz-Lenkerunfallversicherung'}</b>
                </Typography>
                <Typography className='padded-control header'>{'Versicherte Fahrzeuge'}</Typography>
                {state.vehicleData.map((vehicle, index) => {
                    if (!vehicle) {
                        return <React.Fragment key={`summary-${index}`} />;
                    }

                    return (
                        <div className='padded-control' key={`summary-${index}`}>
                            <div
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    padding: '0.5rem',
                                }}
                            >
                                <Typography>
                                    <b>{`Taxifahrzeug ${index + 1}`}</b>
                                </Typography>
                            </div>
                            <div
                                style={{
                                    padding: '0.5rem',
                                }}
                            >
                                <Typography>
                                    <i>
                                        {vehicle.changeInsurer
                                            ? 'Versicherungswechsel'
                                            : 'Neufahrzeug'}
                                    </i>
                                </Typography>
                                {vehicle.changeInsurer && (
                                    <DataRow
                                        header={<Typography>{'Kennzeichen'}</Typography>}
                                        value={
                                            <Typography>
                                                <b>{vehicle.licensePlateNumber}</b>
                                            </Typography>
                                        }
                                    />
                                )}
                                <DataRow
                                    header={<Typography>{'Marke'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{vehicle.manufacture}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'Modell'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{vehicle.carName}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'FIN'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{vehicle.vin}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'Leistung'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{`${vehicle.power} kW`}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'Bruttolistenpreis'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{formatAsCurrency(vehicle.grossListPrice)}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'Sonderausstattung'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{formatAsCurrency(vehicle.optionalEquipment)}</b>
                                        </Typography>
                                    }
                                />
                                <DataRow
                                    header={<Typography>{'Antriebsart'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{vehicle.engineType}</b>
                                        </Typography>
                                    }
                                />
                                {vehicle.changeInsurer && (
                                    <DataRow
                                        header={<Typography>{'Erstzulassung'}</Typography>}
                                        value={
                                            <Typography>
                                                <b>
                                                    {vehicle.firstRegistration?.format(
                                                        'DD.MM.YYYY'
                                                    )}
                                                </b>
                                            </Typography>
                                        }
                                    />
                                )}
                                <DataRow
                                    header={<Typography>{'Leasing'}</Typography>}
                                    value={
                                        <Typography>
                                            <b>{vehicle.leasing === true ? 'Ja' : 'Nein'}</b>
                                        </Typography>
                                    }
                                />
                                {vehicle.leasing && (
                                    <DataRow
                                        header={<Typography>{'Leasinggeber'}</Typography>}
                                        value={
                                            <Typography>
                                                <b>{vehicle.leasingCompany}</b>
                                            </Typography>
                                        }
                                    />
                                )}
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '2fr 1fr',
                                        padding: '0.75rem 0',
                                    }}
                                >
                                    <Typography style={{ margin: '1rem 0' }}>
                                        <b>{'Jahresprämie'}</b>
                                    </Typography>
                                    <div />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>{'Haftpflichtversicherung'}</Typography>
                                        <Typography className='subtext'>
                                            {'inkl. Versicherungssteuer'}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography>
                                            {formatAsCurrency(
                                                vehicle.rate?.components?.find(
                                                    (rate) => rate.type === 'Haftpflicht'
                                                )?.annualGrossPremium
                                            )}
                                        </Typography>
                                    </div>
                                    <Divider
                                        className='row-divider'
                                        style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>{'Vollkaskoversicherung'}</Typography>
                                        <Typography className='subtext'>
                                            {'inkl. Versicherungssteuer'}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography>
                                            {formatAsCurrency(
                                                vehicle.rate?.components?.find(
                                                    (rate) => rate.type === 'Kasko'
                                                )?.annualGrossPremium
                                            )}
                                        </Typography>
                                    </div>
                                    <Divider
                                        className='row-divider'
                                        style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
                                    />
                                    {state.paymentDetails.passengerAccident && (
                                        <>
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                <Typography>{'Kfz-Lenkerunfall-Schutz'}</Typography>
                                                <Typography className='subtext'>
                                                    {'inkl. Versicherungssteuer'}
                                                </Typography>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flexGrow: 1 }} />
                                                <Typography>
                                                    {formatAsCurrency(
                                                        vehicle.rate?.components?.find(
                                                            (rate) => rate.type === 'InsassenUnfall'
                                                        )?.annualGrossPremium
                                                    )}
                                                </Typography>
                                            </div>
                                            <Divider
                                                className='row-divider'
                                                style={{ gridColumnStart: 1, gridColumnEnd: 3 }}
                                            />
                                        </>
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>
                                            <b>{'Gesamt'}</b>
                                        </Typography>
                                        <Typography className='subtext'>
                                            {'inkl. Versicherungssteuer'}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flexGrow: 1 }} />
                                        <Typography className='blue'>
                                            <b>
                                                {formatAsCurrency(
                                                    vehicle.rate?.components?.reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.annualGrossPremium != null
                                                                ? rate.annualGrossPremium
                                                                : 0),
                                                        0
                                                    )
                                                )}
                                            </b>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div
                    className='padded-control'
                    style={{
                        backgroundColor: '#0083a9',
                        borderRadius: '4px',
                        marginTop: '1rem',
                    }}
                >
                    <div
                        className='padded-control'
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '2fr 1fr',
                            padding: '0.75rem 0',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className='in-box'>
                                <b>{'Jahresprämie gesamt / alle Fahrzeuge'}</b>
                            </Typography>
                            <Typography className='in-box'>
                                {'inkl. Versicherungssteuer'}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }} />
                            <Typography className='in-box'>
                                <b>
                                    {formatAsCurrency(
                                        state.vehicleData.reduce((total, vehicle) => {
                                            const components = vehicle.rate?.components;
                                            if (components == null) {
                                                return total;
                                            }

                                            return (
                                                total +
                                                components.reduce(
                                                    (result, rate) =>
                                                        result +
                                                        (rate?.annualGrossPremium != null
                                                            ? rate.annualGrossPremium
                                                            : 0),
                                                    0
                                                )
                                            );
                                        }, 0)
                                    )}
                                </b>
                            </Typography>
                        </div>
                    </div>
                </div>
                {state.paymentDetails.frequency === 'monatlich' && (
                    <div className='padded-control'>
                        <div
                            className='padded-control'
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '2fr 1fr',
                                padding: '0.75rem 0',
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography>
                                    <b>{'monatlich'}</b>
                                </Typography>
                                <Typography className='subtext'>
                                    {'inkl. Versicherungssteuer'}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flexGrow: 1 }} />
                                <Typography>
                                    <b>
                                        {formatAsCurrency(
                                            state.vehicleData.reduce((total, vehicle) => {
                                                const components = vehicle.rate?.components;
                                                if (components == null) {
                                                    return total;
                                                }

                                                return (
                                                    total +
                                                    components.reduce(
                                                        (result, rate) =>
                                                            result +
                                                            (rate?.gross != null ? rate.gross : 0),
                                                        0
                                                    )
                                                );
                                            }, 0)
                                        )}
                                    </b>
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
                {state.vehicleData.some((car) => car.leasing) && (
                    <div className='padded-control infobox'>
                        <ExclamationCircleFilled style={{ color: '#0083a9' }} />
                        <Typography className='infobox'>
                            {
                                'Für die Vinkulierung wird eine einmalige Gebühr in Höhe von EUR 15,- (inkl. 20% Ust.) pro Fahrzeug eingehoben.'
                            }
                        </Typography>
                    </div>
                )}
                <Divider className='summary-divider' />
                <Typography className='padded-control header'>{'Versicherungsnehmer'}</Typography>
                <div
                    className='padded-control'
                    style={{
                        padding: '0 0.5rem',
                    }}
                >
                    {state.personalData.status === 'FIRMA' && (
                        <DataRow
                            header={<Typography>{'Firmenname'}</Typography>}
                            value={
                                <Typography>
                                    <b>{state.personalData.companyName}</b>
                                </Typography>
                            }
                            noDivider
                        />
                    )}
                    <DataRow
                        header={<Typography>{'Straße / Hausnummer / Adresszusatz'}</Typography>}
                        value={
                            <Typography>
                                <b>{`${state.personalData.street ?? ''} ${
                                    state.personalData.houseNumber ?? ''
                                } ${state.personalData.addressAddition ?? ''}`}</b>
                            </Typography>
                        }
                        noDivider={state.personalData.status === 'PRIVAT'}
                    />
                    <DataRow
                        header={<Typography>{'PLZ / Ort'}</Typography>}
                        value={
                            <Typography>
                                <b>{`${state.personalData.postalCode} ${state.personalData.city}`}</b>
                            </Typography>
                        }
                    />
                    <DataRow
                        header={<Typography>{'Email'}</Typography>}
                        value={
                            <Typography>
                                <b>{state.personalData.email}</b>
                            </Typography>
                        }
                    />
                    <DataRow
                        header={<Typography>{'Telefonnummer'}</Typography>}
                        value={
                            <Typography>
                                <b>{state.personalData.phonenumber}</b>
                            </Typography>
                        }
                    />
                </div>
                <Divider className='summary-divider' />
                <Typography className='padded-control header'>{'Zahlungsdaten'}</Typography>
                <div
                    className='padded-control'
                    style={{
                        padding: '0 0.5rem',
                    }}
                >
                    <DataRow
                        header={<Typography>{'IBAN'}</Typography>}
                        value={
                            <Typography>
                                <b>{state.paymentDetails.iban}</b>
                            </Typography>
                        }
                        noDivider
                    />
                    <DataRow
                        header={<Typography>{'Bank'}</Typography>}
                        value={
                            <Typography>
                                <b>{state.paymentDetails.bank}</b>
                            </Typography>
                        }
                    />
                    <DataRow
                        header={<Typography>{'Zahlungsart'}</Typography>}
                        value={
                            <Typography>
                                <b>{'SEPA-Mandat'}</b>
                            </Typography>
                        }
                    />
                    <DataRow
                        header={<Typography>{'Zahlungsfrequenz'}</Typography>}
                        value={
                            <Typography>
                                <b>{state.paymentDetails.frequency}</b>
                            </Typography>
                        }
                    />
                </div>
                <Divider className='summary-divider' />
                <Typography className='padded-control header text-paragraph'>
                    {'Produktinformationsblätter'}
                </Typography>
                <div className='padded-control'>
                    <DocumentLink
                        onRender={() => renderDownloadHint(isHaftpflichtDownloaded)}
                        onClick={() => setIsHaftpflichtDownloaded(true)}
                        title='Produktinformationsblatt Kfz-Haftpflichtversicherung'
                        filePath={Documents.Haftpflicht}
                        style={{ marginBottom: '1rem' }}
                    />
                    <DocumentLink
                        onRender={() => renderDownloadHint(isKaskoDownloaded)}
                        onClick={() => setIsKaskoDownloaded(true)}
                        title='Produktinformationsblatt Kfz-Vollkaskoversicherung'
                        filePath={Documents.Kasko}
                        style={{ marginBottom: '1rem' }}
                    />
                    {state.paymentDetails.passengerAccident && (
                        <DocumentLink
                            onRender={() => renderDownloadHint(isLenkerschutzDownloaded)}
                            onClick={() => setIsLenkerschutzDownloaded(true)}
                            title='Produktinformationsblatt Kfz-Unfallversicherung'
                            filePath={Documents.Lenkerschutz}
                            style={{ marginBottom: '1rem' }}
                        />
                    )}
                    <FormCheckboxInput
                        id='contract'
                        label='Ja, ich bin mit dem Download der Produktinformationsblätter als PDF einverstanden. Ich habe das Recht, vom Versicherer auf mein Verlangen unentgeltlich eine Papierfassung zu erhalten.'
                        ref={downloadAcceptedRef}
                        required
                        onChange={(value) => setValue('downloadAccepted', value)}
                        value={values.downloadAccepted}
                        error={errors.downloadAccepted}
                    />
                </div>
                <Divider className='summary-divider' />
                <Typography className='padded-control header text-paragraph'>
                    {'Vertragsbedingungen inkl. Informationen zum Versicherungsabschluss'}
                </Typography>
                <div className='padded-control'>
                    <DocumentLink
                        onRender={() => renderDownloadHint(isAgbDownloaded)}
                        onClick={() => setIsAgbDownloaded(true)}
                        title='Allgemeine Bedingungen für die Kfz-Versicherung'
                        filePath={avbLink}
                        style={{ marginBottom: '1rem' }}
                    />
                    <FormCheckboxInput
                        id='contract'
                        label='Ich habe die Allgemeinen Informationen (inklusive Belehrung über das Rücktrittsrecht) und die Versicherungsbedingungen heruntergeladen und akzeptiert.'
                        ref={insuranceAcceptedRef}
                        required
                        onChange={(value) => setValue('insuranceAccepted', value)}
                        value={values.insuranceAccepted}
                        error={errors.insuranceAccepted}
                    />
                </div>
                <Divider className='summary-divider' />
                <Typography className='padded-control header text-paragraph'>
                    {'Maklervertrag'}
                </Typography>
                <div className='padded-control'>
                    <DocumentLink
                        onRender={() => renderDownloadHint(isBrokerContractDownloaded)}
                        onClick={() => setIsBrokerContractDownloaded(true)}
                        title='Maklervertrag'
                        filePath={Documents.Maklervertrag}
                        style={{ marginBottom: '1rem' }}
                    />

                    <DocumentLink
                        onRender={() => renderDownloadHint(isAonAgbDownloaded)}
                        onClick={() => setIsAonAgbDownloaded(true)}
                        title='Allgemeine Geschäftsbedingungen Aon'
                        filePath={Documents.AGB}
                        style={{ marginBottom: '1rem' }}
                    />

                    <FormCheckboxInput
                        id='contract'
                        label='Ja, ich beauftrage Aon mit dem Abschluss der Versicherung gemäß dem Maklervertrag und den Allgemeinen Geschäftsbedingungen von Aon.'
                        ref={contractAcceptedRef}
                        required
                        onChange={(value) => setValue('contractAccepted', value)}
                        value={values.contractAccepted}
                        error={errors.contractAccepted}
                    />
                </div>
                <Divider className='summary-divider' />
                <Typography className='padded-control header text-paragraph'>
                    {'Elektronische Kommunikation'}
                </Typography>
                <div className='padded-control'>
                    <Link
                        href='https://www.wienerstaedtische.at/online-abschluesse/bedingung/Vereinbarung_der_elektronischen_Kommunikation'
                        target='_blank'
                    >
                        <Typography
                            className='blue'
                            style={{ marginBottom: '1rem', marginLeft: '0.75rem' }}
                        >
                            <b>Vereinbarung zur elektronischen Kommunikation</b>
                        </Typography>
                    </Link>
                    <FormCheckboxInput
                        id='contract'
                        label='Ja, ich bin mit der Vereinbarung zur elektronischen Kommunikation ausdrücklich einverstanden.'
                        ref={electronicAcceptedRef}
                        required
                        onChange={(value) => setValue('electronicAccepted', value)}
                        value={values.electronicAccepted}
                        error={errors.electronicAccepted}
                    />
                </div>
                {isSubmitDirty && !allDocumentsDownloaded && (
                    <Alert message='Bitte alle Dokumente herunterladen' type='error' />
                )}
                <NavigationContainer
                    forwardText='Kostenpflichtig abschließen'
                    forward={() => {
                        setIsSubmitDirty(true);
                        submit();
                    }}
                    backwardText='Zurück'
                    backward={previousPage}
                />
            </div>
        </div>
    );
};
