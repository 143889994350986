import { Checkbox, Typography } from 'antd';
import React, { ReactNode, forwardRef } from 'react';
import './form-field.scss';

type Props = {
    id: string;
    label: ReactNode;
    value?: boolean | undefined;
    required?: boolean | undefined;
    readonly?: boolean | undefined;
    onChange?: (value: boolean | undefined) => void | Promise<void>;
    error?: ReactNode | undefined;
};

export const FormCheckboxInput: React.ForwardRefExoticComponent<
    Props & React.RefAttributes<HTMLDivElement>
> = forwardRef(
    (
        { id, label, value, readonly, onChange, error }: Props,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        let classes = '';
        if (error != null) {
            classes += ' form-field-error';
        }

        return (
            <div className='padded-control form-field'>
                <div ref={ref}>
                    <Checkbox
                        id={id}
                        className='form-field-input'
                        checked={value}
                        disabled={readonly}
                        onChange={(event) => {
                            onChange && onChange(event.target.checked);
                        }}
                    >
                        <Typography className={classes}>{label}</Typography>
                    </Checkbox>
                </div>
                {error && <div className={classes}>{error}</div>}
            </div>
        );
    }
);
