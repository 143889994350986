export const CUSTOMER_DATA = '/CustomerData' as const;
export const PREFILLING = '/Prefilling' as const;
export const MAJOR_CUSTOMER_OFFER = '/MajorCustomerOffer' as const;
export const CREDIT_CHECK = '/CreditCheck' as const;
export const FIN_CHECK = '/FinCheck' as const;
export const INSURANCE_RATE = '/InsuranceRate' as const;
export const IBAN_VALIDATION = '/IbanValidation' as const;
export const INSURANCE_CONCLUSION = '/InsuranceConclusion' as const;
export const LICENSE_PLATE_REGISTRATION_DATA = '/LPRegistrationData' as const;
export const LICENSE_PLATE_REGISTRATION = '/LicensePlateRegistration' as const;
