import { Typography } from 'antd';
import React from 'react';
import './license-number-result-view.scss';

type Props = {
    resultState: 'exists' | 'completed' | 'Not Found';
};

export const LicenseNumberResultView: React.FC<Props> = ({ resultState }: Props) => {
    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header' style={{ marginLeft: '106px' }}>
                    {'Kennzeichen zu Ihrem Fahrzeug'}
                </Typography>
            </div>
            <div className='view-card-bottom'>
                {resultState === 'completed' && (
                    <>
                        <div className='circle-container'>
                            <div className='checkmark' />
                        </div>
                        <div className='boxed text-paragraph'>
                            <Typography className='padded-control subheader'>
                                {
                                    'Vielen Dank! Ihr Kennzeichen wurde erfolgreich übermittelt und befindet sich gerade in Bearbeitung.'
                                }
                            </Typography>
                        </div>
                        <div className='boxed'>
                            <Typography className='padded-control paragraph'>
                                {
                                    'Ihre Polizze wird in Kürze per Post an Sie verschickt! Wir wünschen Ihnen eine angenehme Fahrt mit Ihrem Fahrzeug!'
                                }
                            </Typography>
                            <Typography className='padded-control paragraph'>
                                {'Beste Grüße'}
                                <br />
                                <b>{'Ihr Aon Team!'}</b>
                            </Typography>
                        </div>
                    </>
                )}
                {resultState === 'exists' && (
                    <>
                        <div className='boxed text-paragraph'>
                            <Typography className='padded-control subheader centered'>
                                {'Ihr Kennzeichen wurde bereits eingegeben!'}
                            </Typography>
                        </div>
                        <div className='boxed'>
                            <Typography className='padded-control paragraph centered'>
                                {'Bei weiteren Fragen wenden Sie sich bitte an den Support.'}
                            </Typography>
                        </div>
                    </>
                )}
                {resultState === 'Not Found' && (
                    <>
                        <div className='boxed text-paragraph'>
                            <Typography className='padded-control subheader centered'>
                                {'Ups, da ist etwas shief gelaufen.'}
                            </Typography>
                        </div>
                        <div className='boxed'>
                            <Typography className='padded-control paragraph centered'>
                                {'Bitte versuchen Sie es noch einmal.'}
                            </Typography>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
