import { Typography } from 'antd';
import React from 'react';
import { ViewProps } from '../view-props';
import './major-customer-result-view.scss';

export const MajorCustomerResultView: React.FC<ViewProps> = () => {
    return (
        <div className='page'>
            <div className='view-card-top'>
                <Typography className='padded-control header'>{'Großkundenangebot'}</Typography>
            </div>
            <div className='view-card-bottom'>
                <div className='boxed text-paragraph'>
                    <Typography className='padded-control subheader'>
                        {'Vielen Dank für Ihre Anfrage.'}
                    </Typography>
                </div>
                <div style={{ padding: '0 1rem' }}>
                    <Typography className='padded-control paragraph'>
                        {
                            'Ihr persönlicher Ansprechpartner wird sich in den nächsten Tagen bei Ihnen melden.'
                        }
                    </Typography>
                    <Typography className='padded-control paragraph'>
                        {'Wir freuen uns auf Sie!'}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
