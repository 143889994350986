import { Typography } from 'antd';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';
import { Page } from 'main-page/pages';
import React, { useEffect } from 'react';
import { useApi } from '../../../lib/api';
import { useQuery } from '../../../lib/shared';
import { PersonalDataState, useFormState } from '../../../lib/state';
import { Documents, NavigationContainer } from '../../components';
import { ViewProps } from '../view-props';
import './landing-view.scss';

export const LandingView: React.FC<ViewProps> = ({ nextPage }: ViewProps) => {
    const api = useApi();
    const linkId = useQuery().get('linkId');
    const { state, updateState } = useFormState();

    useEffect(() => {
        const requestCustomerData = async (): Promise<void> => {
            try {
                const customerData = await api.getCustomerData(linkId ?? undefined);
                if (customerData != null) {
                    const personalData: PersonalDataState = {
                        ...customerData.customerData,
                        lastName: customerData.customerData?.surname,
                        dateOfBirth: customerData.customerData?.dateOfBirth
                            ? dayjs(customerData.customerData?.dateOfBirth)
                            : undefined,
                        validated: 'no',
                    };
                    updateState({
                        ...state,
                        procedureId: customerData.procedureId,
                        personalData: personalData,
                    });
                } else {
                    updateState({
                        ...state,
                        procedureId: undefined,
                        personalData: { validated: 'no' },
                    });
                    nextPage(Page.ErrorViewMissingLinkId);
                }
            } catch (e) {
                updateState({
                    ...state,
                    procedureId: undefined,
                    personalData: { validated: 'no' },
                });
                nextPage(Page.ErrorViewMissingLinkId);
            }
        };

        requestCustomerData();
    }, []);

    const forward = (): void => {
        nextPage(Page.NeedsAnalysisView, false);
    };

    return (
        <div className='page'>
            <Typography className='padded-control header'>
                {'Exklusive Versicherungsverträge für Uber* Partner'}
            </Typography>
            <Typography className='padded-control subheader'>
                {'ab 137€ / Monat je Taxifahrzeug'}
            </Typography>
            <img alt='preview' src='/static/images/landing.png' width={'100%'} />
            <Typography className='padded-control landing-page-hint'>
                {
                    '*Die Versicherungsverträge werden von der Aon Austria GmbH als unabhängiger Versicherungsmakler vermittelt. Uber ist weder eine Versicherungsgesellschaft noch ein Versicherungsvermittler oder -berater. Uber Partner sollten sich beim Abschluss einer Versicherung zusätzlich persönliche Beratung einholen.'
                }
            </Typography>
            <Typography className='padded-control landing-page-hint'>
                {'Die Datenschutzhinweise sind wie folgt bereitgestellt:'}
                <div>
                    <Link
                        target='_blank'
                        rel='noreferrer'
                        href={Documents.Datenschutz}
                        style={{ fontSize: '1em' }}
                    >
                        <u>{'Datenschutzerklärung'}</u>
                    </Link>
                </div>
            </Typography>
            <Typography className='padded-control landing-page-hint'>
                {'** inkl. Versicherungssteuer'}
            </Typography>
            <NavigationContainer forwardText='Jetzt online berechnen' forward={forward} />
            <div className='view-card-bottom' style={{ background: 'transparent' }} />
        </div>
    );
};
